<template>
    <ibnSidebar>
        <button
            v-if="showAddButton"
            class="button has-margin-bottom-20 is-success"
            @click="addRow()"
        >
            <plus-icon class="has-margin-right-5" />Hinzufügen
        </button>
        <div>
            <button
                v-if="tab === 'user' && (!$store.state.auth || $store.state.user.Rights >= 300)"
                class="button is-info is-small has-margin-bottom-10"
                @click="addApiToken()"
            >
                API-Token hinzufügen
            </button>
        </div>
        <div v-if="filterFields.length > 0" class="has-margin-bottom-20">
            <b-input v-model="searchTerm" :placeholder="searchInputText" />
        </div>
        <b-table
            v-if="data[0]"
            id="startupTable"
            :key="updateTable"
            ref="table"
            :data="filterFields.length > 0 ? filteredData : data"
            :selected="selected"
            class="has-margin-bottom-20"
            mobile-cards
            striped
        >
            <b-table-column
                v-for="column in $store.state.ibn.columns[tab].filter(
                    item => !item.hide
                )"
                :key="column.field"
                v-slot="props"
                :field="column.field"
                :label="column.label"
            >
                <b-checkbox
                    v-if="column.type === 'checkbox'"
                    :value="!!props.row[column.field]"
                    style="vertical-align: middle"
                    disabled
                />
                <span v-else>
                    <span v-if="column.type === 'date'">
                        {{ props.row[column.field] | formatDateTimeSec }}
                    </span>
                    <span v-else>
                        {{ props.row[column.field] }}
                    </span>
                </span>
            </b-table-column>
            <b-table-column v-if="tab === 'station'" v-slot="props" label="Letzter Messwert">
                <span v-if="props.row.LastTimestamp">{{ props.row.LastTimestamp | formatDateTimeSec }}</span>
                <b-skeleton v-else :animated="true" width="70%" />
            </b-table-column>
            <b-table-column v-if="tab === 'user'" v-slot="props" label="Kunde">
                {{ props.row.Customer ? props.row.Customer.Name : '' }}
            </b-table-column>
            <b-table-column v-if="tab === 'user'" v-slot="props" label="Sitzungen">
                <router-link
                    :to="{
                        name: 'startup',
                        params: { tab: 'session', filter: props.row.Email },
                    }"
                    class="button is-small is-info has-text-white"
                >
                    {{ props.row.Sessions ? props.row.Sessions.length : '' }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                class="has-text-right"
                label="Aktionen"
                numeric
                style="width: 100%"
            >
                <div class="has-whitespace-nowrap has-text-right is-inline-block">
                    <span
                        v-if="
                            tab === 'user' &&
                                props.row.Email &&
                                props.row.Email.split('@')[1] === 'api.albertfrey.de'
                        "
                    >
                        <key-icon
                            class="has-margin-right-5"
                            size="1.5x"
                            stroke-width="2.5"
                        />
                    </span>
                    <span v-else>
                        <button
                            v-if="tab === 'opcserver'"
                            class="button is-success is-small has-margin-right-5"
                            @click="downloadOPCServer(props.row)"
                        >
                            <download-icon size="1.5x" stroke-width="2.5" />
                        </button>
                        <button
                            v-if="
                                $store.state.ibn.columns[tab].filter(
                                    (item) => !item.editDisabled
                                )[0]
                            "
                            class="button is-info is-small"
                            title="Bearbeiten"
                            @click="editRow(props.row.ID)"
                        >
                            <edit-2-icon size="1.5x" stroke-width="2.5" />
                        </button>
                        <button
                            v-if="
                                tab === 'user' &&
                                    props.row.Sessions &&
                                    props.row.Sessions.length > 0 &&
                                    props.row.Rights < $store.state.user.Rights
                            "
                            class="button is-danger is-small has-margin-left-5"
                            title="Sitzungen entfernen"
                            @click="deleteUserSessions(props.row)"
                        >
                            <slash-icon size="1.5x" stroke-width="2.5" />
                        </button>
                    </span>
                    <button
                        class="button is-danger is-small has-margin-left-5"
                        title="Löschen"
                        @click="deleteRow(props.row.ID)"
                    >
                        <trash-2-icon size="1.5x" stroke-width="2.5" />
                    </button>
                </div>
            </b-table-column>
        </b-table>
        <b-notification
            v-else
            :closable="false"
            class="has-margin-top-20 has-padding-bottom-20"
            role="alert"
            type="is-danger"
        >
            <div class="is-flex flex-row align-items-center">
                <alert-circle-icon class="has-margin-right-10" />Keine
                Daten verfügbar
            </div>
        </b-notification>
    </ibnSidebar>
</template>

<script>
import StationService from "@/services/StationService"
import OPCServerService from "../services/OPCServerService"
import AuthService from "@/services/AuthService"
import ibnSidebar from "../components/ibnSidebar"
import CustomerService from "../services/CustomerService"
import SessionService from "../services/SessionService"
import {
    AlertCircleIcon,
    DownloadIcon,
    Edit2Icon,
    KeyIcon,
    PlusIcon,
    SlashIcon,
    Trash2Icon
} from "vue-feather-icons"

export default {
    name: "StartupIndex",
    components: {
        ibnSidebar,
        SlashIcon,
        AlertCircleIcon,
        PlusIcon,
        Trash2Icon,
        Edit2Icon,
        KeyIcon,
        DownloadIcon
    },
    props: {
        tab: String,
        filter: String
    },
    data() {
        return {
            loading: true,
            success: null,
            error: null,
            props: true,
            updateTable: 0,
            filteredData: [],
            searchTerm: null,
            selected: null,
            data: []
        }
    },
    computed: {
        filterFields() {
            return this.$store.state.ibn.columns[this.tab].filter(
                item => item.filter
            )
        },
        searchInputText() {
            let searchFor = ""
            for (let filterField of this.filterFields) {
                if (searchFor) {
                    searchFor += " oder "
                }
                searchFor += filterField.label
            }
            return `Nach ${searchFor} suchen...`
        },
        showAddButton() {
            if (this.tab !== "session") {
                if (this.tab === "user") {
                    if (this.$store.state.token) return true
                    else return false
                } else return true
            } else return false
        }
    },
    watch: {
        $route() {
            // route change
            this.searchTerm = null
            this.reload()
        },
        searchTerm() {
            this.getFilteredData()
        }
    },
    mounted() {
        this.reload()
    },
    methods: {
        async reload() {
            try {
                this.updateTable++
                switch (this.tab) {
                case "station":
                    await this.getStations()
                    break
                case "opcserver":
                    await this.getOPCServer()
                    break
                case "customer":
                    await this.getCustomer()
                    break
                case "user":
                    await this.getUser()
                    break
                case "session":
                    await this.getSessions()
                    break
                default:
                    this.$router
                        .push({ name: "startup", params: { tab: "opcserver" } })
                        .catch(() => {})
                }
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            } finally {
                if (this.data.length > 0 && this.filterFields.length > 0) {
                    for (let filterField of this.filterFields.map(item => item.field)) {
                        this.$refs.table.filters[filterField] = ""
                    }
                }

                if (this.filter) {
                    this.searchTerm = this.filter
                }
                this.hideLoadingScreen()
            }
        },
        successful() {
            this.success = true
            setTimeout(() => {
                this.loading = false
                this.success = null
            }, 800)
        },
        getFilteredData() {
            let filteredData = []
            for (let filterField of this.filterFields) {
                this.data.filter(item => {
                    let val = item[filterField.field]
                    if (val && val.includes(this.searchTerm)) {
                        filteredData.push(item)
                    }
                })
            }

            this.filteredData = [...new Set(filteredData)]
        },
        async getStations() {
            const stations = (await StationService.getStations()).data
            this.data = stations
            this.filteredData = stations

            // get last timestamp
            let alteredData = stations
            const stationTimestamps = (await StationService.getStationTimestamps()).data
            alteredData.map(data => {
                const item = stationTimestamps.find(item => item.StationID === data.ID)

                data.LastTimestamp = item?.Timestamp || 'Keine Messwerte gefunden'
                return data
            })

            // update data object
            this.data = alteredData
            this.filteredData = alteredData
            this.updateTable++
        },
        async getOPCServer() {
            const response = await OPCServerService.getOPCServer()
            this.data = response.data
            this.filteredData = response.data
        },
        async getUser() {
            const response = await AuthService.getUser()
            this.data = response.data

            // highlight logged-in user
            this.selected = this.data.find(user => user.ID === this.$store.state.user.ID)

            this.filteredData = response.data
        },
        async getSessions() {
            const response = await SessionService.getSessions()
            this.data = response.data

            // highlight current session
            this.selected = this.data.find(session => session.ID === this.$store.state.session.ID)

            this.filteredData = response.data
        },
        async getCustomer() {
            const response = await CustomerService.getCustomer()
            this.data = response.data

            // highlight current customer
            this.selected = this.data.find(customer => customer.ID === this.$store.state.user.CustomerID)

            this.filteredData = response.data
        },
        addRow() {
            this.$router.push({ name: "startupCreate", params: { tab: this.tab } })
        },
        addApiToken() {
            this.$router.push({ name: "startupCreateToken" })
        },
        editRow(ID) {
            if (!this.$store.state.ibn.columns[this.tab].editDisabled) {
                this.$router.push({
                    name: "startupEdit",
                    params: { tab: this.tab, id: ID }
                })
            }
        },
        async deleteRow(ID) {
            this.confirmDialog(
                {
                    title: "Datensatz löschen",
                    message: "Sind Sie sich sicher, dass die diesen Datensatz unwiderruflich entfernen möchten?"
                }, async () => {
                    try {
                        switch (this.tab) {
                        case "station":
                            await StationService.deleteStation(ID)
                            break
                        case "opcserver":
                            await OPCServerService.deleteOPCServer(ID)
                            break
                        case "customer":
                            await CustomerService.deleteCustomer(ID)
                            break
                        case "user":
                            await AuthService.deleteUser(ID)
                            break
                        case "session":
                            await SessionService.deleteSession(ID)
                            break
                        }
                    } catch (err) {
                        console.error(
                            "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                            err
                        )
                        this.errorAlert(
                            err.response.data.error
                                ? err.response.data.error
                                : "Serververbindung fehlgeschlagen."
                        )
                    } finally {
                        await this.reload()
                        if(this.searchTerm) this.getFilteredData()
                    }
                })
        },
        async deleteUserSessions(user) {
            this.confirmDialog(
                {
                    title: "Sitzungen löschen",
                    message: `Sind Sie sich sicher, dass Sie alle Sitzungen des Benutzers <b>${user.Email}</b> entfernen möchten?`
                }, async () => {
                    try {
                        await AuthService.deleteUserSessions(user.ID)

                        this.successAlert("Die Sitzungen des Benutzers wurden entfernt.")
                    } catch (err) {
                        console.error(
                            "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                            err
                        )
                        this.errorAlert(
                            err.response.data.error
                                ? err.response.data.error
                                : "Serververbindung fehlgeschlagen."
                        )
                    } finally {
                        await this.reload()
                        if(this.searchTerm) this.getFilteredData()
                    }
                })
        },
        downloadOPCServer(opcserver) {
            function download(filename, text) {
                var element = document.createElement("a")
                element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
                )
                element.setAttribute("download", filename)

                element.style.display = "none"
                document.body.appendChild(element)

                element.click()

                document.body.removeChild(element)
            }

            download(`${opcserver.Name}.json`, JSON.stringify(opcserver))
        }
    }
}
</script>

<style scoped>
#startupTable {
  white-space: nowrap !important;
}

#stationTable {
  color: white;
}
</style>
<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .sidebar-layout {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.table-wrapper table.table {
    border: 0;
}

tr.is-selected {
    background-color: #2d3436 !important;
    color: white !important;
    font-weight: 700;
}

</style>
