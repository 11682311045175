<template>
    <ibnSidebar>
        <section>
            <form @submit.prevent="sendData()">
                <b-field label="Kunde">
                    <b-select
                        v-model="data.CustomerID"
                        placeholder="Kunde auswählen"
                        required
                    >
                        <option
                            v-for="c in customer"
                            :key="c.ID"
                            :value="c.ID"
                        >
                            {{ c.Name }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Gültig bis">
                    <b-datetimepicker
                        v-model="data.expires"
                        :min-datetime="new Date()"
                        class="column is-4 is-left-paddingless"
                        horizontal-time-picker
                        open-on-focus
                        required
                        rounded
                    />
                </b-field>
                <b-field>
                    <button class="button is-info" type="submit">
                        <save-icon class="has-margin-right-5" />Speichern
                    </button>
                    <button
                        class="button is-danger is-outlined has-margin-left-10"
                        @click.prevent="
                            $router.push({ name: 'startup', params: { tab: 'user' } })
                        "
                    >
                        Abbrechen
                    </button>
                </b-field>
            </form>
        </section>
    </ibnSidebar>
</template>

<script>
import ibnSidebar from "../components/ibnSidebar"
import AuthService from "../services/AuthService"
import copy from "copy-to-clipboard"
import CustomerService from "../services/CustomerService"
import { SaveIcon } from "vue-feather-icons"

export default {
    name: "StartupCreateToken",
    components: {
        ibnSidebar,
        SaveIcon
    },
    data() {
        return {
            data: {
                CustomerID: 1,
                expires: new Date()
            },
            customer: []
        }
    },
    async mounted() {
        // get customer for name
        this.customer = (await CustomerService.getCustomer()).data
        // set expire to a date in 20 years
        let dateIn20Years = new Date()
        dateIn20Years.setFullYear(this.data.expires.getFullYear() + 20)
        this.data.expires = dateIn20Years

        this.hideLoadingScreen()
    },
    methods: {
        async sendData() {
            try {
                const response = await AuthService.createAPIToken(this.data)

                copy(response.data)

                this.$buefy.dialog.alert({
                    title: "API-Token generiert",
                    message:
            "<span class='has-text-black'>Der API-Token befindet sich in der Zwischenablage.</span>",
                    type: "is-success",
                    hasIcon: true,
                    icon: "check",
                    iconPack: "fa",
                    ariaRole: "alertdialog",
                    ariaModal: true
                })

                this.$router.push({ name: "startup", params: { tab: "user" } })
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            }
        }
    }
}
</script>

<style scoped>
.media {
  background: red !important;
  align-items: center;
}

.media-content {
  background: red !important;
  color: #000 !important;
}
</style>
