<template>
    <div class="is-full-width">
        <Navbar>
            <template slot="icon">
                <trending-up-icon class="has-margin-right-10" />
            </template>
            <template slot="title">
                ALFlex Trend
            </template>
            <b-navbar-item tag="div">
                <ProfileDropdown />
            </b-navbar-item>
            <b-navbar-item
                v-if="$store.state.user && $store.state.token"
                tag="div"
            >
                <b-dropdown
                    aria-role="list"
                    hoverable
                    position="is-bottom-left"
                >
                    <button
                        slot="trigger"
                        class="button is-dark"
                    >
                        <user-icon class="has-margin-right-5" />{{ $store.state.user.Email }}
                    </button>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="$router.push({ name: 'userSettings' })"
                    >
                        <tool-icon class="has-margin-right-5" />Benutzereinstellungen
                    </b-dropdown-item>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="logout"
                    >
                        <log-out-icon class="has-margin-right-5" />Abmelden
                    </b-dropdown-item>
                </b-dropdown>
            </b-navbar-item>
            <b-navbar-item
                class="has-margin-right-10"
                tag="div"
            >
                <BackButton back-to-home />
            </b-navbar-item>
        </Navbar>
        <div class="container">
            <div class="has-margin-top-15 has-margin-left-15 has-margin-right-15">
                <Header>
                    <layers-icon slot="icon" />
                    Messstelle: {{ station.Name }}
                </Header>

                <div class="block" style="color: rgb(185, 185, 185);">
                    Anzeige der Messwerte der letzten 24 Stunden.
                </div>

                <!-- Table -->
                <b-table
                    ref="table"
                    :columns="tableColumns"
                    :data="station.values"
                    class="has-margin-bottom-20"
                    mobile-cards
                    striped
                />

                <article class="panel dark-panel no-border has-margin-bottom-25">
                    <p class="panel-heading">
                        Statistik
                    </p>
                    <div class="panel-block is-flex-direction-column is-align-items-start">
                        <div>Mittel: {{ Number(+stats.avg).toFixed(station.Decimals) }}</div>
                        <div>Minimum: {{ Number(+stats.min).toFixed(station.Decimals) }}</div>
                        <div>Maximum: {{ Number(+stats.max).toFixed(station.Decimals) }}</div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar'
import BackButton from "../components/BackButton"
import ProfileDropdown from '../components/ProfileDropdown'
import Header from '../components/Header'

import {
    ToolIcon,
    LogOutIcon,
    UserIcon,
    TrendingUpIcon,
    LayersIcon
} from "vue-feather-icons"
import StationValueService from '../services/StationValueService'

export default {
    name: 'StationDetails',
    components: {
        Navbar,
        BackButton,
        ProfileDropdown,
        Header,
        LogOutIcon,
        UserIcon,
        ToolIcon,
        TrendingUpIcon,
        LayersIcon
    },
    props: {
        id: Number
    },
    data() {
        return {
            station: {},
            stats: {
                avg: null,
                min: null,
                max: null
            },
            tableColumns: [{
                field: 'Timestamp',
                label: 'Zeitstempel'
            }, {
                field: 'Value',
                label: 'Wert'
            }, {
                field: 'Annotation',
                label: 'Bemerkung'
            }]
        }
    },
    mounted() {
        this.reload()
    },
    methods: {
        async reload() {
            const response = await StationValueService.getStationValues(this.id)
            const { station, values, stats } = response.data

            station.values = values.map(item => {
                item.Timestamp = this.formatDate(item.Timestamp)
                return item
            })

            this.station = station
            this.stats = stats

            this.hideLoadingScreen()
        }
    }
}
</script>

<style scoped>

</style>
