<template>
    <component :is="iconComponent" />
</template>

<script>
import {
    ArrowUpIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    EyeIcon,
    EyeOffIcon,
    AlertCircleIcon,
    PlusIcon,
    DownloadIcon
} from "vue-feather-icons"

export default {
    name: "FeatherPack",
    components: {
        ChevronDownIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
        EyeIcon,
        EyeOffIcon,
        CheckIcon,
        ArrowUpIcon,
        AlertCircleIcon,
        PlusIcon,
        DownloadIcon
    },
    props: {
        icon: [String, Array],
        size: String,
        customClass: String
    },
    computed: {
        iconComponent() {
            return `${this.icon[1]}-icon`
        }
    }
}
</script>
<style>
svg.feather {
  stroke-width: 2;
  fill: none;
}
</style>
