import Api from "@/services/Api"

export default {
    getAuthStatus() {
        return Api().get("/auth")
    },
    register(params) {
        return Api().post("/auth/register", params)
    },
    login(params) {
        return Api().post("/auth/login", params, {
            withCredentials: true
        })
    },
    changePassword(params) {
        return Api().put("/auth/password", params)
    },
    getUser() {
        return Api().get("/auth/user")
    },
    showUser(ID) {
        return Api().get(`/auth/user/${ID}`)
    },
    editLoggedInUser(params) {
        return Api().put("/auth/user", params)
    },
    editUser(ID, params) {
        return Api().put(`/auth/user/${ID}`, params)
    },
    deleteUser(ID) {
        return Api().delete(`/auth/user/${ID}`)
    },
    deleteUserSessions(ID) {
        return Api().delete(`/auth/user/${ID}/session`)
    },
    getCSRFToken() {
        return Api().get("/auth/csrf", {
            withCredentials: true
        })
    },
    createAPIToken(params) {
        return Api().post("/auth/token", params)
    },
    logout() {
        return Api().post("/auth/logout")
    }
}
