<template>
    <b-navbar :active.sync="active" :close-on-click="false" type="is-primary">
        <template slot="brand">
            <b-navbar-item class="is-left-marginless" tag="div">
                <h1
                    class="title has-text-white has-padding-left-20 is-flex flex-row align-items-center has-margin-top-5 has-margin-bottom-5 has-margin-right-10"
                    style="
            font-family: Inter;
            font-weight: 900;
            font-size: 1.5rem !important;
          "
                >
                    <slot name="icon" />
                    <slot name="title" />
                </h1>
            </b-navbar-item>
        </template>
        <template slot="end">
            <slot />
        </template>
    </b-navbar>
</template>

<script>
export default {
    name: "NavBar",
    computed: {
        active: {
            get() {
                return this.$store.state.navbarActive
            },
            set(newValue) {
                this.$store.commit('setNavbarActive', newValue)
            }
        }
    }
}
</script>

<style>
.navbar-item {
  padding-left: 0 !important;
  padding-right: 0.3rem !important;
}

.navbar-item,
.dropdown button {
  font-family: Inter;
  font-weight: 700;
}

a.dropdown-item {
  font-family: Inter;
  font-weight: 500;
  outline-style: none;
}
</style>