import Api from "@/services/Api"
import store from "../store"

export default {
    getStations() {
        return Api().get('/station')
    },
    getStationTimestamps() {
        return Api().get('/station/timestamp')
    },
    showStation(ID) {
        return Api().get(`/station/${ID}`)
    },
    addStation(params) {
        return Api().post("/station", params)
    },
    editStation(ID, params) {
        return Api().put(`/station/${ID}`, params)
    },
    deleteStation(ID) {
        return Api().delete(`/station/${ID}`)
    },
    getMatchingStations(searchTerm) {
        return Api().post(`/profiles/${store.state.profile.ID}/station`, {
            term: searchTerm
        })
    },
    getSelectedStations() {
        return Api().get(`/profiles/${store.state.profile.ID}/station`)
    },
    addProfileStation(stationID) {
        return Api().get(
            `/profiles/${store.state.profile.ID}/station/${stationID}/add`
        )
    },
    removeStation(stationID) {
        return Api().delete(
            `/profiles/${store.state.profile.ID}/station/${stationID}`
        )
    },
    editProfileStation(stationID, params) {
        return Api().put(
            `/profiles/${store.state.profile.ID}/station/${stationID}`,
            params
        )
    }
}
