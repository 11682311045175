<template>
    <div class="is-full-width">
        <div id="mainWindow">
            <!-- Navbar Section -->
            <Navbar>
                <template slot="icon">
                    <settings-icon class="has-margin-right-10" />
                </template>
                <template slot="title">
                    ALFlex Trend
                </template>
                <b-navbar-item tag="div">
                    <ProfileDropdown />
                </b-navbar-item>
                <b-navbar-item
                    v-if="$store.state.user && $store.state.token"
                    tag="div"
                >
                    <b-dropdown
                        aria-role="list"
                        hoverable
                        position="is-bottom-left"
                    >
                        <button
                            slot="trigger"
                            class="button is-dark"
                        >
                            <user-icon class="has-margin-right-5" />{{ $store.state.user.Email }}
                        </button>
                        <b-dropdown-item
                            aria-role="listitem"
                            @click="$router.push({ name: 'userSettings' })"
                        >
                            <tool-icon class="has-margin-right-5" />Benutzereinstellungen
                        </b-dropdown-item>
                        <b-dropdown-item
                            aria-role="listitem"
                            @click="logout"
                        >
                            <log-out-icon class="has-margin-right-5" />Abmelden
                        </b-dropdown-item>
                    </b-dropdown>
                </b-navbar-item>
                <b-navbar-item
                    class="has-margin-right-10"
                    tag="div"
                >
                    <BackButton back-to-home />
                </b-navbar-item>
            </Navbar>
            <!-- Content -->
            <div class="container">
                <div class="row head-row">
                    <div class="col-12 ml-auto">
                        <div
                            id="main-content"
                            class="container has-margin-top-15 has-margin-left-15 has-margin-right-15"
                        >
                            <!-- Graph Header -->
                            <Header :fullwidth="true">
                                <users-icon slot="icon" />
                                <div>
                                    <div
                                        style="display: flex; flex-direction: row; justify-content: space-between;"
                                    >
                                        <input
                                            ref="profile"
                                            v-model="inputProfile"
                                            :readonly="!profileEditingMode"
                                            class="headline-section borderless-input mr-3 is-full-width is-paddingless"
                                            maxlength="50"
                                            style="text-transform: none !important"
                                            type="text"
                                            @keyup.enter="renameProfile()"
                                            @keyup.esc="cancelProfileRename()"
                                        >
                                        <div
                                            v-if="!profileEditingMode"
                                            class="is-flex flex-row align-items-center"
                                        >
                                            <b-tooltip
                                                label="Profil umbenennen"
                                                position="is-bottom"
                                                style="text-transform: none"
                                                class="has-margin-left-5"
                                                type="is-dark"
                                            >
                                                <RoundedButton
                                                    type="is-info"
                                                    :margin-left="false"
                                                    @click.native="enableEditingMode"
                                                >
                                                    <edit-3-icon />
                                                </RoundedButton>
                                            </b-tooltip>
                                            <b-tooltip
                                                label="Profil löschen"
                                                position="is-bottom"
                                                style="text-transform: none"
                                                class="has-margin-left-5"
                                                type="is-dark"
                                            >
                                                <RoundedButton
                                                    type="is-danger"
                                                    :margin-left="false"
                                                    @click.native="deleteProfile"
                                                >
                                                    <delete-icon />
                                                </RoundedButton>
                                            </b-tooltip>
                                        </div>
                                        <div v-else class="is-flex flex-row align-items-center">
                                            <RoundedButton type="is-success" @click.native="renameProfile()">
                                                <check-icon stroke-width="3" />
                                            </RoundedButton>
                                            <RoundedButton type="is-danger" @click.native="cancelProfileRename()">
                                                <x-icon stroke-width="3" />
                                            </RoundedButton>
                                        </div>
                                    </div>
                                </div>
                            </Header>

                            <!-- Messstelle suchen -->
                            <article class="panel dark-panel no-border">
                                <p class="panel-heading">
                                    <plus-icon class="has-margin-right-5" />Messstelle
                                    hinzufügen
                                </p>
                                <form @submit.prevent="getMatchingStations">
                                    <div
                                        :key="-2"
                                        class="panel-block"
                                    >
                                        <b-autocomplete
                                            v-model="searchTerm"
                                            :data="results"
                                            class="control is-full-width"
                                            open-on-focus
                                            placeholder="Suchen Sie nach einer Messstelle..."
                                            @select="(option) => addStation(option.ID)"
                                        >
                                            <template slot-scope="props">
                                                {{ props.option | formatStationName }}
                                            </template>
                                        </b-autocomplete>
                                    </div>
                                </form>
                            </article>

                            <!-- Einzelne Einstellungen  -->
                            <article
                                v-if="selected[0]"
                                class="panel dark-panel no-border"
                            >
                                <p class="panel-heading">
                                    <check-circle-icon
                                        class="has-margin-right-5"
                                    />Ausgewählte Messstellen
                                </p>
                                <div
                                    v-for="s in selected"
                                    :key="s.ID"
                                    class="table-rows"
                                >
                                    <div class="panel-block">
                                        <span
                                            class="text-white has-cursor-pointer"
                                            style="font-weight: 400"
                                            @click="s.collapsed = !s.collapsed"
                                        >
                                            {{ s | formatStationName}}
                                        </span>

                                        <RoundedButton
                                            class="button is-primary"
                                            @click.native="s.collapsed = !s.collapsed"
                                        >
                                            <chevron-up-icon v-if="s.collapsed" />
                                            <chevron-down-icon v-else />
                                        </RoundedButton>

                                        <!-- align items right -->
                                        <div class="is-flex flex-row" style="margin-left: auto">
                                            <b-tooltip
                                                v-if="!$store.state.auth || $store.state.user.Rights >= 200"
                                                label="Messstelle bearbeiten"
                                                position="is-bottom"
                                                class="has-margin-left-5"
                                                type="is-dark"
                                            >
                                                <RoundedButton
                                                    class="button is-info is-small"
                                                    type="button"
                                                    :margin-left="false"
                                                    @click.native="editStation(s.ID)"
                                                >
                                                    <edit-2-icon size="1.5x" />
                                                </RoundedButton>
                                            </b-tooltip>

                                            <!-- Color Indicator -->
                                            <b-tooltip
                                                class="color-indicator has-margin-left-5"
                                                label="Farbe der Messstelle ändern"
                                                position="is-bottom"
                                                type="is-dark"
                                            >
                                                <RoundedButton
                                                    :style="{
                                                        background: s.Color,
                                                        borderColor: s.Color,
                                                        fontSize: '0.4em'
                                                    }"
                                                    :margin-left="false"
                                                    class="button is-small is-rounded-button"
                                                    type="button"
                                                    @click.native="openColorMenu(`s-${s.ID}`)"
                                                >
                                                    <input
                                                        :id="`s-${s.ID}`"
                                                        class="colorInput"
                                                        style="opacity: 0; width: 0px; position: absolute"
                                                        type="color"
                                                        @change="changeStationColor(s.ID)"
                                                    >
                                                </RoundedButton>
                                            </b-tooltip>
                                            <b-tooltip
                                                label="Messstelle vom Profil entfernen"
                                                position="is-left"
                                                class="has-margin-left-5"
                                                type="is-dark"
                                            >
                                                <RoundedButton
                                                    class="button is-danger is-small"
                                                    type="button"
                                                    :margin-left="false"
                                                    @click.native="removeStation(s.ID)"
                                                >
                                                    <minus-circle-icon
                                                        size="1.5x"
                                                        stroke-width="2.5"
                                                    />
                                                </RoundedButton>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                    <div v-if="s.collapsed" class="table-border" style="padding: 0 0.75em 0.5em 0.75em; font-size: .9rem;">
                                        <table v-if="s.IsStepLine === 1" class="table minimal-table" style="margin-bottom: .5rem;">
                                            <tbody>
                                                <tr>
                                                    <td><b>Offset</b></td>
                                                    <td>
                                                        <b-input
                                                            v-model="s.Offset"
                                                            size="is-small"
                                                            type="number"
                                                            step="1"
                                                            style="width: 5rem;"
                                                            min="-10"
                                                            max="10"
                                                            rounded
                                                            @input="changeOffset(s)"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table class="table minimal-table" style="margin-bottom: .5rem;">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <span class="has-text-grey-light is-uppercase" style="font-weight: bolder">Y-Achse</span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><b>Anzeigen</b></td>
                                                    <td>
                                                        <b-switch
                                                            v-model="s.YAxisShown"
                                                            class="has-no-hover-color is-right-marginless"
                                                            type="is-info"
                                                            @input="toggleYAxisVisibility(s)"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr v-if="!s.YAxisHidden">
                                                    <td><b>Name</b></td>
                                                    <td>
                                                        <div class="flex-row justify-items-center" style="margin-left: -0.2rem;">
                                                            <BorderlessInput
                                                                :ref="'y' + s.ID"
                                                                :bold="false"
                                                                :fit-content-length="true"
                                                                :fullwidth="false"
                                                                :readonly="typeof s.newName !== 'string'"
                                                                :value="typeof s.newName === 'string' ? s.newName : s.YAxisName"
                                                                maxlength="30"
                                                                @enter="renameYAxis(s)"
                                                                @escape="closeEditMode(s, 'y')"
                                                                @input.native="s.newName = $event.target.value"
                                                            />

                                                            <div v-if="typeof s.newName === 'string'" class="flex-row">
                                                                <RoundedButton type="is-success" @click.native="renameYAxis(s)">
                                                                    <check-icon stroke-width="3" />
                                                                </RoundedButton>
                                                                <RoundedButton type="is-danger" @click.native="closeEditMode(s, 'y')">
                                                                    <x-icon stroke-width="3" />
                                                                </RoundedButton>
                                                            </div>
                                                            <div v-else class="is-flex">
                                                                <b-tooltip
                                                                    class="color-indicator has-margin-left-5"
                                                                    label="Y-Achse umbenennen"
                                                                    position="is-bottom"
                                                                    type="is-dark"
                                                                >
                                                                    <RoundedButton
                                                                        type="is-info"
                                                                        :margin-left="false"
                                                                        @click.native="openEditMode(s, 'y')"
                                                                    >
                                                                        <edit-3-icon />
                                                                    </RoundedButton>
                                                                </b-tooltip>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Min. Wert</b>
                                                    </td>
                                                    <td>
                                                        <div class="is-flex align-items-center">
                                                            <b-input
                                                                v-model="s.YAxisMin"
                                                                size="is-small"
                                                                type="number"
                                                                step="any"
                                                                style="width: 5rem;"
                                                                :max="s.YAxisMax"
                                                                rounded
                                                                @focusout.native="changeYAxisMin(s)"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Max. Wert</b>
                                                    </td>
                                                    <td>
                                                        <div class="is-flex align-items-center">
                                                            <b-input
                                                                v-model="s.YAxisMax"
                                                                size="is-small"
                                                                type="number"
                                                                step="any"
                                                                style="width: 5rem;"
                                                                :min="s.YAxisMin"
                                                                rounded
                                                                @focusout.native="changeYAxisMax(s)"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="field is-flex is-bottom-marginless" style="vertical-align: bottom;" />
                                    </div>
                                    <div v-if="s.collapsed" class="table-border" style="padding: 0 0.75em 0.5em 0.75em; font-size: .9rem; margin-bottom: 1.5rem;">
                                        <table class="table minimal-table">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <span class="has-text-grey-light is-uppercase" style="font-weight: bolder">Statistische Werte</span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><b>Mittel anzeigen</b></td>
                                                    <td>
                                                        <b-switch
                                                            v-model="s.ShowAverage"
                                                            class="has-no-hover-color is-right-marginless"
                                                            type="is-info"
                                                            @input="toggleShowStatisticValue(s, 'average')"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Minimum anzeigen</b></td>
                                                    <td>
                                                        <b-switch
                                                            v-model="s.ShowMin"
                                                            class="has-no-hover-color is-right-marginless"
                                                            type="is-info"
                                                            @input="toggleShowStatisticValue(s, 'min')"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>Maximum anzeigen</b></td>
                                                    <td>
                                                        <b-switch
                                                            v-model="s.ShowMax"
                                                            class="has-no-hover-color is-right-marginless"
                                                            type="is-info"
                                                            @input="toggleShowStatisticValue(s, 'max')"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </article>
                            <!-- Grafana Einstellungen  -->
                            <article
                                v-if="$store.state.settings.grafana.active"
                                class="panel dark-panel no-border"
                            >
                                <p class="panel-heading">
                                    <link-icon
                                        class="has-margin-right-5"
                                    />Grafana konfigurieren
                                </p>
                                <!-- todo: do not show button and ignore enter on form when already submitted -->
                                <form
                                    class="panel-block"
                                    @submit.prevent="setGrafanaURL"
                                >
                                    <b-input
                                        v-model="grafanaURL"
                                        class="control is-full-width"
                                        placeholder="Fügen Sie einen Grafana-Link ein..."
                                    />
                                    <transition name="fade">
                                        <button v-if="grafanaURLChanged && grafanaURL" class="button is-success has-margin-left-10" type="submit">
                                            <check-icon />
                                        </button>
                                    </transition>
                                </form>
                                <div v-if="!!$store.state.profile.GrafanaURL" class="panel-block">
                                    <button class="button is-danger is-small" @click="deleteGrafanaURL">
                                        <delete-icon size="1.3x" class="has-margin-right-5" />Link entfernen
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../router"
import ProfileService from "@/services/ProfileService"
import StationService from "@/services/StationService"

import store from "../store"
import Vue from "vue"
import Header from "../components/Header"
import Navbar from "../components/Navbar"
import BackButton from "../components/BackButton"
import ProfileDropdown from '../components/ProfileDropdown'
import RoundedButton from "@/components/RoundedButton"
import BorderlessInput from "../components/BorderlessInput"

import {
    CheckCircleIcon,
    DeleteIcon,
    Edit2Icon,
    Edit3Icon,
    HomeIcon,
    ToolIcon,
    PlusIcon,
    LogOutIcon,
    UserIcon,
    UsersIcon,
    SettingsIcon,
    MinusCircleIcon,
    CheckIcon,
    XIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    LinkIcon
} from "vue-feather-icons"

export default {
    name: "SettingIndex",
    components: {
        ProfileDropdown,
        BorderlessInput,
        RoundedButton,
        Header,
        Navbar,
        BackButton,
        UsersIcon,
        UserIcon,
        Edit3Icon,
        DeleteIcon,
        CheckCircleIcon,
        ToolIcon,
        PlusIcon,
        LogOutIcon,
        Edit2Icon,
        SettingsIcon,
        MinusCircleIcon,
        CheckIcon,
        XIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        LinkIcon
    },
    props: {
        permalink: String
    },
    data() {
        return {
            searchTerm: "",
            editingMode: false,
            loading: true,
            success: null,
            selected: [],
            results: [],
            newProfileName: false,
            selectedProfile: "Kein Profil verfügbar",
            grafanaURL: null,
            grafanaURLChanged: false // showing button only after Grafana URL has been changed
        }
    },
    computed: {
        inputProfile: {
            get() {
                // get right value
                return this.profileEditingMode ? this.newProfileName : this.$store.state.profile.Name // return new profile name if profile is being edited at the moment
            },
            set(newValue) {
                if(this.profileEditingMode) {
                    // currently editing profile name
                    this.newProfileName = newValue
                }
            }
        },
        profileEditingMode: {
            get() {
                return typeof this.newProfileName === 'string'
            }
        }
    },
    watch: {
        $route(routeTo, routeFrom) {
            if (routeFrom.name !== "index") {
                this.reload()
            }
        },
        searchTerm() {
            this.getMatchingStations()
        },
        grafanaURL(newURL) {
            if(this.grafanaURLChanged) return

            if(newURL && newURL !== store.state.profile.GrafanaURL) this.grafanaURLChanged = true
        }
    },
    mounted() {
        this.reload()
    },
    methods: {
        async reload(updatePath = false) {
            // results und searchTerm behalten
            this.editingMode = false

            if (this.searchTerm) {
                this.getMatchingStations()
            }

            try {
                await this.getAllProfiles()
                await this.getProfileInfo()
                this.grafanaURL = store.state.profile.GrafanaURL
                await this.getStationInfo()
                if (updatePath) {
                    router
                        .push({
                            name: "settings",
                            params: { permalink: store.state.profile.Permalink }
                        })
                        .catch(error => {
                            if (error.name !== "NavigationDuplicated") {
                                throw error
                            }
                        })
                }
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert("Serververbindung fehlgeschlagen.")
            }

            this.loading = false
            this.hideLoadingScreen()
        },
        async getAllProfiles() {
            const response = await ProfileService.getAllProfiles()
            store.commit("setProfiles", response.data)
        },
        async getProfileInfo() {
            // Server nach Profilinfos fragen
            var profilePermalink = this.permalink
                ? this.permalink
                : store.state.profile.Permalink
            // Profilinfos nochmal anfordern, damit sie immer up-to-date sind
            const latestProfile = await ProfileService.getLatestProfile(
                profilePermalink
            )
            this.selectedProfile = latestProfile.data.Name

            // todo: rewrite conditional
            if (
                latestProfile.data.ID !== store.state.profile.ID ||
                latestProfile.data.Name !== store.state.profile.Name ||
                latestProfile.data.Permalink !== store.state.profile.Permalink ||
                latestProfile.data.GrafanaURL !== store.state.profile.GrafanaURL ||
                this.permalink !== latestProfile.data.Permalink
            ) {
                store.commit("setProfile", latestProfile.data)
                router
                    .push({
                        name: "settings",
                        params: { permalink: latestProfile.data.Permalink }
                    })
                    .catch(error => {
                        if (error.name !== "NavigationDuplicated") {
                            throw error
                        }
                    })
            } else if (!latestProfile) {
                // Serverdaten sind leer, kein Profil gefunden
                this.errorAlert("Es konnte kein Profil gefunden werden.")
            }
        },
        getStationName(id) {
            const station = this.selected.find(station => station.ID === id)
            return station.Name
        },
        openColorMenu(id) {
            const colorInput = document.querySelector(`#${id}`)
            colorInput.focus()
            colorInput.click()
        },
        async changeOffset(series) {
            try {
                await StationService.editProfileStation(series.ID, {
                    Offset: series.Offset
                })

                this.successful()
            } catch {
                this.errorAlert("Der Offset konnte nicht geändert werden.")
            }
        },
        async changeYAxisMin(series) {
            try {
                await StationService.editProfileStation(series.ID, {
                    YAxisMin: +series.YAxisMin
                })

                this.successful()
            } catch {
                this.errorAlert("Der Min. Wert der Y-Achse konnte nicht geändert werden.")
            }
        },
        async changeYAxisMax(series) {
            try {
                await StationService.editProfileStation(series.ID, {
                    YAxisMax: +series.YAxisMax
                })

                this.successful()
            } catch {
                this.errorAlert("Der Max. Wert der Y-Achse konnte nicht geändert werden.")
            }
        },
        async toggleYAxisVisibility(series) {
            try {
                await StationService.editProfileStation(series.ID, {
                    YAxisHidden: !series.YAxisShown
                })

                this.successful()
            } catch {
                this.errorAlert(
                    "Die Sichtbarkeit der Y-Achse konnte nicht geändert werden."
                )
            }
        },
        async toggleShowStatisticValue(series, statisticValue) {
            const requestBody = {}

            switch(statisticValue) {
            case "average":
                requestBody.ShowAverage = this.convertBooleanToInt(series.ShowAverage)
                break
            case "min":
                requestBody.ShowMin = this.convertBooleanToInt(series.ShowMin)
                break
            case "max":
                requestBody.ShowMax = this.convertBooleanToInt(series.ShowMax)
                break
            default:
                return
            }

            try {
                const response = await StationService.editProfileStation(series.ID, requestBody)
                if (response.data.affectedRows === 1) {
                    this.successful()
                } else {
                    throw new Error()
                }
            } catch(e) {
                this.errorAlert("Die Sichtbarkeit des Wertes konnte nicht geändert werden.")
            }
        },
        editStation(ID) {
            this.$router.push({
                name: "startupEdit",
                params: { tab: "station", id: ID }
            })
        },
        async removeStation(id) {
            this.confirmDialog(
                {
                    title: "Messstelle aus Profil entfernen",
                    message: `Sind Sie sich sicher, dass Sie die Messstelle <b>${this.convertHtmlToText(this.getStationName(id))}</b> aus Ihrem Profil entfernen möchten?`
                },
                async () => {
                    try {
                        const response = await StationService.removeStation(id)
                        if (response.data.affectedRows === 1) {
                            this.successful()
                        } else {
                            this.errorAlert(
                                "Die Messtelle konnte nicht aus Ihrem Profil entfernt werden."
                            )
                        }
                    } catch {
                        this.errorAlert(
                            "Die Messtelle konnte nicht aus Ihrem Profil entfernt werden."
                        )
                    }
                }
            )
        },
        openEditMode(obj, appendRef) {
            Vue.set(obj, 'newName', appendRef === 'y' ? obj.YAxisName : obj.Name)
            Vue.nextTick(() => {
                this.$refs[`${appendRef}${obj.ID}`][0].focus()
            })
        },
        closeEditMode(obj, appendRef, resize = true) {
            if(resize) {
                let ref = this.$refs[`${appendRef}${obj.ID}`][0]

                const resizeObserver = new ResizeObserver(function() {
                    ref.adjustInputWidth()
                    resizeObserver.unobserve(ref.$el)
                })

                resizeObserver.observe(ref.$el)
            }

            Vue.set(obj, 'newName', false)

            this.$refs[`${appendRef}${obj.ID}`][0].blur()
        },
        async addStation(stationID) {
            try {
                const response = await StationService.addProfileStation(stationID)
                if (response.data.ID) {
                    this.successful()
                } else {
                    if (response.data.error) {
                        this.errorAlert(response.data.error)
                    } else {
                        this.errorAlert("Die Messstelle konnte nicht hinzugefügt werden.")
                    }
                }
            } catch {
                this.errorAlert("Die Messstelle konnte nicht hinzugefügt werden.")
            }
        },
        async renameYAxis(obj) {
            const stationID = obj.ID

            const { newName, YAxisName: Name } = this.selected.find(station => station.ID === stationID)

            if(Name !== newName && newName) {
                try {
                    const response = await StationService.editProfileStation(stationID, {
                        YAxisName: newName
                    })

                    if (response.data.affectedRows === 1) {
                        this.closeEditMode(obj, 'y', false)
                        this.successful()
                    } else {
                        this.errorAlert("Die Y-Achse konnte nicht umbenannt werden.")
                    }
                } catch (err) {
                    if (err.response.data.error) {
                        this.errorAlert(err.response.data.error)
                    } else {
                        this.errorAlert("Die Y-Achse konnte nicht umbenannt werden.")
                    }
                    this.closeEditMode(obj, 'y')
                }
            }
        },
        async changeStationColor(id) {
            const color = document.querySelector(`#s-${id}`).value

            try {
                const response = await StationService.editProfileStation(id, {
                    Color: color
                })
                if (response.data.affectedRows === 1) {
                    this.successful()
                } else {
                    this.errorAlert(
                        "Die Farbe der Messstelle konnte nicht geändert werden."
                    )
                }
            } catch {
                this.errorAlert(
                    "Die Farbe der Messstelle konnte nicht geändert werden."
                )
            }
        },
        async getStationInfo() {
            const response = await StationService.getSelectedStations()

            const collapsedStations = this.selected.filter(station => station.collapsed)

            this.selected = response.data.sort((a, b) => {
                return a.ID - b.ID
            }).map(s => {
                s.YAxisShown = !s.YAxisHidden
                s.ShowAverage = !!s.ShowAverage
                s.ShowMin = !!s.ShowMin
                s.ShowMax = !!s.ShowMax
                s.collapsed = collapsedStations.some(station => station.ID === s.ID)
                return s
            })
        },
        async getMatchingStations() {
            if (this.searchTerm) {
                const response = await StationService.getMatchingStations(
                    this.searchTerm
                )
                if (this.results != response.data) {
                    this.results = response.data
                }
            } else {
                this.results = []
            }
        },
        enableEditingMode() {
            this.newProfileName = this.selectedProfile
            Vue.nextTick(() => {
                this.$refs.profile.focus()
            })
        },
        async renameProfile() {
            let profileName = this.newProfileName
            this.showLoadingScreen()
            // reset value
            event.target.value = ""
            if (profileName !== this.selectedProfile) {
                // nur ändern, wenn Name verändert wurde
                try {
                    const response = await ProfileService.editProfile({
                        Name: profileName
                    })
                    if (response.data.Permalink) {
                        router
                            .push({
                                name: "settings",
                                params: { permalink: response.data.Permalink }
                            })
                            .then(() => {
                                this.successful("Das Profil wurde umbenannt.")
                            })
                            .catch(error => {
                                if (error.name !== "NavigationDuplicated") {
                                    throw error
                                } else {
                                    this.successful("Das Profil wurde umbenannt.")
                                }
                            })
                    } else if (response.data.error) {
                        this.errorAlert(response.data.error)
                    } else {
                        this.errorAlert("Ihr Profil konnte nicht umbenannt werden.")
                    }
                } catch {
                    this.errorAlert("Ihr Profil konnte nicht umbenannt werden.")
                }
            }
            this.newProfileName = false
            this.hideLoadingScreen()
        },
        cancelProfileRename() {
            // reset value
            this.newProfileName = false
        },
        async deleteProfile() {
            this.confirmDialog(
                {
                    title: "Profil löschen",
                    message: `Sind Sie sich sicher, dass Sie das Profil <b>${this.convertHtmlToText(this.selectedProfile)}</b> löschen möchten?`
                },
                async () => {
                    try {
                        const response = await ProfileService.deleteProfile()
                        if (response.data.affectedRows === 1) {
                            this.successful(
                                `Das Profil <b>${this.selectedProfile}</b> wurde erfolgreich gelöscht.`,
                                false,
                                false
                            )
                            router.push({ name: "index" })
                        } else {
                            this.errorAlert("Ihr Profil konnte nicht gelöscht werden.")
                        }
                    } catch {
                        this.errorAlert("Ihr Profil konnte nicht gelöscht werden.")
                    }
                }
            )
        },
        async setGrafanaURL() {
            try {
                await ProfileService.editProfile({
                    GrafanaURL: this.grafanaURL
                })

                this.successful()
            } catch {
                this.errorAlert("Die Grafana URL konnte nicht gesetzt werden.")
            }
        },
        deleteGrafanaURL() {
            this.grafanaURL = ''
            this.setGrafanaURL()
        },
        async successful(text, reload = true, closeAlertOnRouteChange = true) {
            if (reload) {
                await this.reload()
            }
            this.successAlert(
                text ? text : "Änderungen erfolgreich gespeichert.",
                closeAlertOnRouteChange
            )
        }
    }
}
</script>

<style>
.color-indicator > .tooltip-trigger {
  display: flex;
}

.table-rows:nth-child(even), .table-rows:nth-child(odd) button.is-primary, .field button.is-primary-dark {
    background: rgb(62, 62, 62);
}

.table-rows:nth-child(odd) button.is-primary:hover, .field button.is-primary-dark:hover {
    background: rgb(66, 66, 66);
}

table.minimal-table tbody tr td {
    vertical-align: inherit;
}

table.minimal-table { border-collapse: separate; background-color: transparent; }

table.minimal-table tbody tr td {
    border: 2px solid transparent;
}

table.minimal-table tbody tr:first-child td:first-child { border-top-left-radius: 10px; }
table.minimal-table tbody tr:first-child td:last-child { border-top-right-radius: 10px; }
table.minimal-table tbody tr:last-child td:first-child { border-bottom-left-radius: 10px; }
table.minimal-table tbody tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.table-rows:nth-child(odd) table.minimal-table tbody {
    background: rgba(62, 62, 62, 0.7);
}
</style>

<style scoped>
.fade-enter-active {
  transition: opacity 1.2s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
