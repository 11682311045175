import Api from "@/services/Api"
import store from "../store"

export default {
    getLatestProfile(profileID) {
        return Api().get(profileID ? `profile/${profileID}` : `profile`)
    },
    getAllProfiles() {
        return Api().get("profiles")
    },
    createProfile(params) {
        return Api().post("profiles/create", params)
    },
    deleteProfile() {
        return Api().delete(`profiles/${store.state.profile.ID}`)
    },
    editProfile(params) {
        return Api().put(`profiles/${store.state.profile.ID}`, params)
    }
}
