import Api from "@/services/Api"

export default {
    getCustomer() {
        return Api().get("/customer")
    },
    addCustomer(params) {
        return Api().post("/customer", params)
    },
    showCustomer(ID) {
        return Api().get(`/customer/${ID}`)
    },
    editCustomer(ID, params) {
        return Api().put(`/customer/${ID}`, params)
    },
    deleteCustomer(ID) {
        return Api().delete(`/customer/${ID}`)
    }
}
