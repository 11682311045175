import Api from "@/services/Api"
import store from "../store"

export default {
    getGraphValues() {
        return Api().get(`profiles/${store.state.profile.ID}/graph`)
    },
    getCustomGraphValues(params) {
        return Api().post(`profiles/${store.state.profile.ID}/graph`, params)
    },
    getStationValues(stationID) {
        return Api().get(`station/${stationID}/values`)
    },
    editGraphAnnotation(stationValueID, params) {
        return Api().put(`profiles/${store.state.profile.ID}/graph/${stationValueID}`, params)
    }
}
