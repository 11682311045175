import AuthService from "@/services/AuthService"
import store from "@/store"
import router from '@/router'
import moment from 'moment'

let toasts = []

export const methods = {
    showToast(message, type, closeOnRouteChange) {
        let toast = this.$buefy.toast.open({
            message,
            type,
            duration: 4000,
            queue: false
        })

        if (closeOnRouteChange) {
            toasts.push(toast)
        }

        return toast
    },
    errorAlert(message, closeOnRouteChange = true) {
        this.showToast(message, "is-danger", closeOnRouteChange)
    },
    successAlert(message, closeOnRouteChange = true) {
        this.showToast(message, "is-success", closeOnRouteChange)
    },
    infoAlert(message, closeOnRouteChange = true) {
        this.showToast(message, "is-info", closeOnRouteChange)
    },
    confirmDialog(config, onConfirm, onCancel) {
        const {
            title,
            message,
            confirmText = "Ja",
            cancelText = "Abbrechen",
            icon,
            type
        } = config
        this.$buefy.dialog.confirm({
            title,
            message: `<span class='has-text-black'>${message}</span>`,
            confirmText,
            cancelText,
            type: "is-danger",
            hasIcon: true,
            ...(icon && { icon }),
            ...(type && { type }),
            ...(onConfirm && { onConfirm }),
            ...(onCancel && { onCancel })
        })
    },
    promptDialog(config, onConfirm) {
        const { title, placeholder = null, inputValue = null, maxlength } = config
        this.$buefy.dialog.prompt({
            title,
            inputAttrs: {
                type: "text",
                placeholder,
                value: inputValue,
                ...(maxlength && { maxlength })
            },
            confirmText: "OK",
            cancelText: "Abbrechen",
            trapFocus: true,
            // closeOnConfirm: false,
            ...(onConfirm && { onConfirm })
        })
    },
    closeAllAlerts() {
        for (let toast of toasts) {
            toast.close()
        }
        toasts = []
    },
    showLoadingScreen() {
        store.commit("setLoading", true)
    },
    hideLoadingScreen() {
        if (store.state.loading) {
            store.commit("setLoading", false)
        }
    },
    checkAuthStatus(auth) {
    // check if auth status has changed
        if (auth !== store.state.auth) {
            store.commit("setAuth", auth)

            // if auth is disabled and any user or token exists in the store, delete them
            if (!auth && (store.state.user || store.state.token)) {
                store.commit("setUser", null)
                store.commit("setToken", null)
            }
        }
    },
    convertHtmlToText(value) {
        const div = document.createElement("div")
        div.innerHTML = value
        return div.innerText
    },
    async logout(serverLogout = true, routerParams = null) {
        if(serverLogout) await AuthService.logout()

        store.commit("setToken", null)
        store.commit("setUser", null)
        store.commit("setProfile", {})
        store.commit("setProfiles", [])

        router.push({
            name: "login",
            ...(routerParams && { params: routerParams })
        })
    },
    formatDate(date) {
        return moment(date).isValid() ? moment(date).format("DD.MM.YYYY HH:mm:ss") : date
    },
    openInNewTab(routeConfig) {
        const { href } = this.$router.resolve(routeConfig)
        window.open(href, '_blank')
    },
    convertBooleanToInt(boolean) {
        return boolean ? 1 : 0
    }
}
