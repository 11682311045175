import Vue from "vue"
import Router from "vue-router"
import Dashboard from "./views/Dashboard.vue"
import Settings from "./views/Settings.vue"
import LoginIndex from "./views/Login.vue"
import StartupIndex from "./views/Startup.vue"
import StartupCreate from "./views/StartupCreate.vue"
import StartupEdit from "./views/StartupEdit.vue"
import StartupSettings from "./views/StartupSettings"
import UserSettings from "./views/UserSettings"
import StartupCreateToken from "./views/StartupCreateToken"
import Help from "@/views/Help"
import StationDetails from "./views/StationDetails"

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/ibn/settings",
            name: "startupSettings",
            component: StartupSettings,
            meta: {
                title: "Inbetriebnahme"
            }
        },
        {
            path: "/ibn/token/create",
            name: "startupCreateToken",
            component: StartupCreateToken,
            meta: {
                title: "Inbetriebnahme"
            }
        },
        {
            path: "/ibn/:tab/create",
            props: true,
            name: "startupCreate",
            component: StartupCreate,
            meta: {
                title: "Inbetriebnahme"
            }
        },
        {
            path: "/ibn/:tab/:id/edit",
            props: true,
            name: "startupEdit",
            component: StartupEdit,
            meta: {
                title: "Inbetriebnahme"
            }
        },
        {
            path: "/ibn/:tab?/:filter?",
            props: true,
            name: "startup",
            component: StartupIndex,
            meta: {
                title: "Inbetriebnahme"
            }
        },
        {
            path: "/login",
            props: true,
            name: "login",
            component: LoginIndex,
            meta: {
                title: "Login"
            }
        },
        {
            path: "/hilfe",
            name: "help",
            component: Help,
            meta: {
                title: "Hilfe"
            }
        },
        {
            path: "/:permalink?",
            props: true,
            name: "dashboard",
            component: Dashboard
        },
        {
            path: "/",
            name: "index",
            component: Dashboard
        },
        {
            path: "/benutzer/einstellungen",
            name: "userSettings",
            component: UserSettings
        },
        {
            path: "/:permalink/einstellungen",
            props: true,
            name: "settings",
            component: Settings
        },
        {
            path: "/station/:id",
            props: ({ params }) => ({ id: Number.parseInt(params.ID) }),
            name: "stationDetails",
            component: StationDetails
        }
    ],
    mode: "history"
})
