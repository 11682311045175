var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        'button is-small is-flex flex-column',
        _vm.type,
        _vm.marginLeft ? 'has-margin-left-5' : ''
    ],style:({
        width: _vm.size,
        height: _vm.size
    })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }