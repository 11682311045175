<template>
    <div class="is-full-width">
        <Navbar>
            <template slot="icon">
                <trending-up-icon class="has-margin-right-10" />
            </template>

            <template slot="title">
                ALFlex Trend
            </template>
            <b-navbar-item tag="div">
                <ProfileDropdown />
            </b-navbar-item>
            <b-navbar-item
                v-if="$store.state.profile && $store.state.profile.ID"
                tag="div"
            >
                <router-link
                    :to="{ name: 'settings', params: { permalink: permalink } }"
                    class="button is-dark"
                    title="Konfigurieren Sie Ihr aktuelles Profil"
                >
                    <settings-icon class="has-margin-right-5" />Profileinstellungen
                </router-link>
            </b-navbar-item>
            <b-navbar-item
                v-if="$store.state.user && $store.state.token"
                class="has-margin-right-10"
                tag="div"
            >
                <b-dropdown aria-role="list" hoverable position="is-bottom-left">
                    <button slot="trigger" class="button is-dark">
                        <user-icon class="has-margin-right-5" />{{ $store.state.user.Email }}
                    </button>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="$router.push({ name: 'userSettings' })"
                    >
                        <tool-icon class="has-margin-right-5" />Benutzereinstellungen
                    </b-dropdown-item>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="logout"
                    >
                        <log-out-icon class="has-margin-right-5" />Abmelden
                    </b-dropdown-item>
                </b-dropdown>
            </b-navbar-item>
        </Navbar>

        <div
            id="mainContent"
            class="has-margin-top-15 has-margin-left-15 has-margin-right-15"
        >
            <!-- Graph Header -->
            <Header>
                <list-icon v-if="tabularDisplay" slot="icon" />
                <bar-chart2-icon v-else slot="icon" />
                {{ tabularDisplay ? 'Tabelle' : 'Graph' }}
                <template slot="body">
                    <!-- Reload Button -->
                    <b-tooltip
                        v-if="!customRange.enabled"
                        label="Neu laden"
                        position="is-bottom"
                        class="has-margin-left-5"
                        type="is-dark"
                    >
                        <RoundedButton :margin-left="false" @click.native="reloadData()">
                            <refresh-cw-icon stroke-width="3" />
                        </RoundedButton>
                    </b-tooltip>
                    <!-- Home Button -->
                    <b-tooltip
                        v-if="chartHasData && !tabularDisplay"
                        label="Zurücksetzen"
                        position="is-bottom"
                        class="has-margin-left-5"
                        type="is-dark"
                    >
                        <RoundedButton :margin-left="false" @click.native="resetChart">
                            <skip-back-icon stroke-width="3" />
                        </RoundedButton>
                    </b-tooltip>
                    <!-- Custom Range Button -->
                    <b-tooltip
                        v-if="chartHasData && !tabularDisplay"
                        :label="
                            customRange.enabled ? 'Filter ändern' : 'Zeitbereich auswählen'
                        "
                        position="is-bottom"
                        class="has-margin-left-5"
                        type="is-dark"
                    >
                        <RoundedButton :margin-left="false" @click.native="openCustomRangeDialog">
                            <calendar-icon stroke-width="3" />
                        </RoundedButton>
                    </b-tooltip>
                    <!-- Reset Range Button -->
                    <b-tooltip
                        label="Auswahl zurücksetzen"
                        position="is-bottom"
                        type="is-dark"
                    >
                        <RoundedButton
                            v-if="customRange.enabled"
                            @click.native="resetCustomRange"
                        >
                            <filter-icon stroke-width="3" />
                        </RoundedButton>
                    </b-tooltip>
                    <!-- Tabellarische Anzeige einschalten -->
                    <div v-if="chartHasData">
                        <b-tooltip
                            v-if="!tabularDisplay"
                            label="Tabellarische Anzeige"
                            position="is-bottom"
                            class="has-margin-left-5"
                            type="is-dark"
                        >
                            <RoundedButton
                                :margin-left="false"
                                @click.native="tabularDisplay = true"
                            >
                                <list-icon stroke-width="3" />
                            </RoundedButton>
                        </b-tooltip>
                        <!-- Tabellarische Anzeige ausschalten -->
                        <b-tooltip
                            v-else
                            label="Graph"
                            position="is-bottom"
                            class="has-margin-left-5"
                            type="is-dark"
                        >
                            <RoundedButton
                                :margin-left="false"
                                @click.native="tabularDisplay = false"
                            >
                                <bar-chart2-icon stroke-width="3" />
                            </RoundedButton>
                        </b-tooltip>
                    </div>
                </template>
            </Header>

            <!-- Reload Meldung -->
            <transition name="fade">
                <div v-if="reloading" class="row reloadChartAlert">
                    <div class="col-auto pl-0 pr-0">
                        <div class="card text-left bg-dark">
                            <div class="card-header custom-card-header text-white">
                                <div
                                    class="spinner-border custom-spinner text-light mr-2"
                                    role="status"
                                />
                                <span>Neue Messdaten werden geladen...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- table -->
            <div v-if="tabularDisplay">
                <b-table
                    ref="table"
                    :data="tableData"
                    :columns="tableColumns"
                    class="has-margin-bottom-20"
                    mobile-cards
                    striped
                />
            </div>

            <!-- graph component -->
            <div v-show="chartHasData && !showSpinner" id="chart" class="w-100 h-75">
                <apex-chart
                    v-show="!tabularDisplay"
                    id="graph"
                    ref="graph"
                    :options="chart.options"
                    :series="chart.series"
                    height="720"
                    type="line"
                    @beforeZoom="chartBeforeZoom"
                    @legendClick="chartLegendClick"
                    @scrolled="chartScrolled"
                    @mounted="chartMounted"
                    @markerClick="markerClick"
                    @contextmenu.native="contextMenu"
                />
            </div>

            <b-notification
                v-if="!chartHasData"
                :closable="false"
                role="alert"
                type="is-danger"
            >
                <div class="is-flex flex-row align-items-center">
                    <alert-circle-icon class="has-margin-right-10" />Keine Daten verfügbar
                </div>
            </b-notification>

            <!-- Datenreihen -->
            <div v-if="!tabularDisplay">
                <!-- Datenreihen Header Section -->
                <Header
                    v-if="chart.series.length > 0 && chart.series[0].data[0]"
                    style="font-size: 12px !important"
                    :class="dataRowsPanelHidden ? 'has-margin-bottom-40' : ''"
                >
                    <database-icon slot="icon" />
                    <div class="is-flex flex-row align-items-center">
                        Datenreihen
                        <b-tooltip
                            :label="dataRowsPanelHidden ? 'Reiter anzeigen' : 'Reiter ausblenden'"
                            position="is-bottom"
                            class="has-margin-left-5"
                            style="text-transform: none; letter-spacing: normal;"
                            type="is-dark"
                        >
                            <RoundedButton class="is-primary-dark" @click.native="dataRowsPanelHidden = !dataRowsPanelHidden">
                                <eye-icon v-if="dataRowsPanelHidden" />
                                <eye-off-icon v-else />
                            </RoundedButton>
                        </b-tooltip>
                    </div>
                </Header>

                <!-- Datenreihen Panel -->
                <transition name="fade-faster">
                    <article
                        v-if="chart.series.length > 0 && chart.series[0].data[0] && !dataRowsPanelHidden"
                        class="panel dark-panel no-border has-margin-bottom-25"
                    >
                        <p class="panel-heading">
                            <check-circle-icon class="has-margin-right-5" />Ausgewählte Messstellen
                        </p>
                        <div v-for="series in chart.series" :key="series.id" class="panel-block">
                            <div class="field is-flex flex-row align-items-center">
                                <b-switch
                                    v-model="series.show"
                                    class="has-no-hover-color"
                                    type="is-info"
                                    @input="toggleSeries(series)"
                                >
                                    {{ series | formatStationName }}
                                </b-switch>
                                <RoundedButton :margin-left="false" class="is-primary-dark" @click.native="openStationDetails(series.id)">
                                    <external-link-icon />
                                </RoundedButton>
                                <span class="ml-2" style="color: rgb(185, 185, 185);">
                                    <span v-if="series.stats.avg">Mittel: {{ Number(+series.stats.avg).toFixed(series.decimals) }}</span>
                                    <span v-if="series.stats.min"><span v-if="series.stats.avg"> • </span>Minimum: {{ Number(+series.stats.min).toFixed(series.decimals) }}</span>
                                    <span v-if="series.stats.max"><span v-if="series.stats.avg || series.stats.min"> • </span>Maximum: {{ Number(+series.stats.max).toFixed(series.decimals) }}</span>
                                </span>
                            </div>
                        </div>
                    </article>
                </transition>
            </div>

            <div v-if="$store.state.settings.grafana.active && $store.state.profile.GrafanaURL && $store.state.profile.GrafanaURL.length > 0" class="has-margin-top-30">
                <Header>
                    Grafana
                    <layers-icon slot="icon" />
                </Header>
                <div style="height: 30rem; margin-bottom: 2rem; ">
                    <iframe :src="$store.state.profile.GrafanaURL" height="100%" width="100%" />
                </div>
            </div>
        </div>

        <b-modal
            :active.sync="customRange.showModal"
            :destroy-on-hide="false"
            aria-modal
            aria-role="dialog"
            has-modal-card
            trap-focus
        >
            <div class="modal-card" style="width: 26rem; height: 45rem">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Zeitbereich auswählen
                    </p>
                </header>
                <section class="modal-card-body">
                    <b-field label="von">
                        <div class="columns">
                            <div class="column">
                                <b-datepicker
                                    v-model="customRange.from.date"
                                    :first-day-of-week="1"
                                    :day-names="customRange.dayNames"
                                    :month-names="customRange.monthNames"
                                    horizontal-time-picker
                                    icon-pack="feather"
                                    rounded
                                />
                            </div>

                            <div class="column">
                                <b-select
                                    v-model="customRange.from.hours"
                                    class="has-margin-right-10"
                                    placeholder="Stunden"
                                    style="display: inline-block"
                                >
                                    <option value="0">
                                        00
                                    </option>
                                    <option v-for="index in 23" :key="index" :value="index">
                                        {{ index < 10 ? `0${index}` : index }}
                                    </option>
                                </b-select>

                                <b-select
                                    v-model="customRange.from.minutes"
                                    placeholder="Minuten"
                                    style="display: inline-block"
                                >
                                    <option value="0">
                                        00
                                    </option>
                                    <option v-for="index in 59" :key="index" :value="index">
                                        {{ index < 10 ? `0${index}` : index }}
                                    </option>
                                </b-select>
                            </div>
                        </div>
                    </b-field>

                    <b-field label="bis">
                        <div class="columns">
                            <div class="column">
                                <b-datepicker
                                    v-model="customRange.to.date"
                                    :first-day-of-week="1"
                                    :day-names="customRange.dayNames"
                                    :month-names="customRange.monthNames"
                                    horizontal-time-picker
                                    icon-pack="feather"
                                    rounded
                                />
                            </div>

                            <div class="column">
                                <b-select
                                    v-model="customRange.to.hours"
                                    class="has-margin-right-10"
                                    placeholder="Stunden"
                                    style="display: inline-block"
                                >
                                    <option value="0">
                                        00
                                    </option>
                                    <option v-for="index in 23" :key="index" :value="index">
                                        {{ index < 10 ? `0${index}` : index }}
                                    </option>
                                </b-select>

                                <b-select
                                    v-model="customRange.to.minutes"
                                    placeholder="Minuten"
                                    style="display: inline-block"
                                >
                                    <option value="0">
                                        00
                                    </option>
                                    <option v-for="index in 59" :key="index" :value="index">
                                        {{ index < 10 ? `0${index}` : index }}
                                    </option>
                                </b-select>
                            </div>
                        </div>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button class="button is-primary" :loading="customRange.isLoading" @click="setCustomRange">
                        OK
                    </b-button>
                    <button
                        class="button"
                        type="button"
                        @click="customRange.showModal = false"
                    >
                        Abbrechen
                    </button>
                </footer>
            </div>
        </b-modal>
        <b-modal
            id="graphAnnotationsModal"
            :active.sync="annotations.showModal"
            :destroy-on-hide="false"
            :auto-focus="false"
            aria-modal
            aria-role="dialog"
            has-modal-card
            trap-focus
        >
            <form class="modal-card" style="width: 26rem;" @submit.prevent="editAnnotation">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ `Bemerkung ${isDatapointAlreadyAnnotated ? 'bearbeiten' : 'hinzufügen'}` }}
                    </p>
                </header>
                <div class="modal-card-body">
                    <div class="media">
                        <div class="media-content">
                            <div class="field is-block">
                                <div class="control is-full-width has-margin-bottom-5">
                                    <input
                                        ref="annotationInput"
                                        v-model="annotations.text"
                                        class="input"
                                        maxlength="50"
                                        placeholder="Bemerkung"
                                        required
                                        style="width: 100%"
                                        type="text"
                                    >
                                </div>
                                <div class="control is-full-width">
                                    <a
                                        v-if="isDatapointAlreadyAnnotated"
                                        class="has-text-danger is-danger"
                                        @click="removeAnnotation"
                                    >
                                        Bemerkung entfernen
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="annotations.showModal = false">
                        Abbrechen
                    </button>
                    <button class="button is-primary" type="submit">
                        OK
                    </button>
                </footer>
            </form>
        </b-modal>
    </div>
</template>

<script>
import router from "@/router"
import ProfileService from "@/services/ProfileService"
import StationService from "@/services/StationService"
import StationValueService from "@/services/StationValueService"
import Navbar from "@/components/Navbar"
import Header from "@/components/Header"
import RoundedButton from "@/components/RoundedButton"
import ProfileDropdown from "@/components/ProfileDropdown"
import store from "@/store"
import moment from "moment"
import German from "apexcharts/dist/locales/de.json"
import writeXlsxFile from "write-excel-file"

import {
    AlertCircleIcon,
    BarChart2Icon,
    CalendarIcon,
    CheckCircleIcon,
    DatabaseIcon,
    FilterIcon,
    ListIcon,
    ToolIcon,
    LogOutIcon,
    RefreshCwIcon,
    SettingsIcon,
    TrendingUpIcon,
    UserIcon,
    SkipBackIcon,
    ExternalLinkIcon,
    LayersIcon,
    EyeIcon,
    EyeOffIcon
} from "vue-feather-icons"
import Vue from 'vue'

export default {
    name: "DashboardIndex",
    components: {
        Navbar,
        Header,
        RoundedButton,
        ProfileDropdown,
        BarChart2Icon,
        DatabaseIcon,
        UserIcon,
        SettingsIcon,
        ToolIcon,
        LogOutIcon,
        TrendingUpIcon,
        RefreshCwIcon,
        CalendarIcon,
        AlertCircleIcon,
        CheckCircleIcon,
        FilterIcon,
        ListIcon,
        SkipBackIcon,
        ExternalLinkIcon,
        LayersIcon,
        EyeIcon,
        EyeOffIcon
    },
    props: {
        permalink: String
    },
    data() {
        const self = this
        return {
            tableColumns: [{
                field: 'SeriesName',
                label: 'Station'
            }, {
                field: 'Timestamp',
                label: 'Zeitstempel'
            }, {
                field: 'Value',
                label: 'Ist-Wert'
            }],
            chart: {
                state: {
                    xaxis: {
                        min: null,
                        max: null
                    }
                },
                series: [],
                options: {
                    annotations: {
                        points: []
                    },
                    animations: {
                        // todo: add option to enable animations
                        enabled: false,
                        animateGradually: {
                            enabled: false
                        },
                        dynamicAnimation: {
                            enabled: false
                        }
                    },
                    theme: {
                        mode: "dark"
                    },
                    grid: {
                        borderColor: "#000000",
                        yaxis: {
                            lines: {
                                show: false
                            }
                        },
                        row: {
                            colors: undefined,
                            opacity: 0
                        }
                    },
                    chart: {
                        id: "graph",
                        locales: [German],
                        defaultLocale: "de",
                        foreColor: "#FFFFFF", // #373d3f
                        background: "#212121",
                        fontFamily: "Inter",
                        type: "area",
                        toolbar: {
                            tools: {
                                reset: true,
                                customIcons: [{
                                    icon: '<img src="download.svg">',
                                    index: 4,
                                    title: 'Excel-Datei exportieren',
                                    class: 'has-margin-left-10',
                                    click: self.exportData
                                }]
                            }
                        }
                    },
                    colors: [],
                    stroke: {
                        width: 3,
                        curve: "straight"
                    },
                    markers: {
                        size: 0,
                        strokeColor: "#fff",
                        strokeWidth: 3,
                        strokeOpacity: 1,
                        fillOpacity: 1,
                        hover: {
                            size: 6
                        }
                    },
                    tooltip: {
                        shared: false
                    },
                    xaxis: {
                        type: "datetime", // https://apexcharts.com/docs/datetime/
                        labels: {
                            formatter: function (value, timestamp, index) {
                                return moment(timestamp).format("DD.MM.YYYY HH:mm:ss")
                            }
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    yaxis: [],
                    legend: {
                        position: "top",
                        horizontalAlign: "left"
                    },
                    fill: {
                        type: "solid"
                    },
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            firstTimestamp: null,
            loadOutOfRangeValues: false,
            loading: true,
            reloading: false,
            hasChartMounted: false,
            profileButton: false,
            error: null,
            selected: [],
            annotations: {
                text: null, // user text input (v-model)
                showModal: false,
                current: {
                    ID: null,
                    text: null
                }
            },
            tabularDisplay: false,
            dataRowsPanelHidden: false,
            customRange: {
                enabled: false,
                showModal: false,
                isLoading: false,
                from: {
                    date: new Date(),
                    hours: 0,
                    minutes: 0
                },
                to: {
                    date: new Date(),
                    hours: 0,
                    minutes: 0
                },
                dayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                monthNames: [
                    "Januar",
                    "Februar",
                    "März",
                    "April",
                    "Mai",
                    "Juni",
                    "Juli",
                    "August",
                    "September",
                    "Oktober",
                    "November",
                    "Dezember"
                ]
            }
        }
    },
    computed: {
        selectedProfile() {
            return store.state.profile.Name
        },
        currentProfileID() {
            return store.state.profile.ID
        },
        chartHasData() {
            return this.chart.series.length > 0 && this.chart.series[0]?.data?.length > 0
        },
        showSpinner: {
            get() {
                return store.state.loading
            },
            set(newVal) {
                store.commit("setLoading", newVal)
            }
        },
        tableData() {
            return this.chart.series.map(series => {
                const latestDataset = series.data[series.data.length-1]
                const { x: Timestamp, y: Value } = latestDataset

                return {
                    SeriesName: series.name,
                    Timestamp: this.formatDate(Timestamp),
                    Value
                }
            })
        },
        isDatapointAlreadyAnnotated() {
            return this.annotations.current.text?.length > 0
        }
    },
    watch: {
        $route(routeTo, routeFrom) {
            if (routeFrom.name !== "index") {
                window.location.reload()
            }
        }
    },
    async mounted() {
        await this.reload()

        // Verhindert das Default Context Menu bei Rechtsklick auf die Chart Komponente auf Windows
        document.oncontextmenu = (event => {
            if(document.getElementById('graphAnnotationsModal')?.contains(event.target)) {
                event.preventDefault()
            } else {
                event.returnValue = true
            }
        })
    },
    methods: {
        async reload(skipProfileInfo = false) {
            this.error = null
            try {
                if (!skipProfileInfo) {
                    await this.getProfileInfo()
                } else {
                    if (store.state.profile.ID) {
                        await this.getStationInfo()
                        await this.getGraphValues()
                    } else {
                        this.errorAlert("Es konnte kein Profil gefunden werden.")
                        this.profileButton = true
                    }

                    if (!this.error) {
                        this.hideLoadingScreen()
                        this.loading = false
                    }
                }
            } catch (err) {
                this.hideLoadingScreen()
                this.loading = false
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            }
        },
        contextMenu(event) {
            event.preventDefault()
        },
        markerClick(event, chartContext, { seriesIndex, dataPointIndex }) {
            // Nur wenn rechte Maustaste gedrückt wurde
            if(event.which === 3) {
                const datapoint = this.chart.series[seriesIndex].data[dataPointIndex]

                // Hat Datensatz bereits eine Bemerkung?
                const currentAnnotation = this.chart.options.annotations.points.find(annotation => annotation.ID === datapoint.ID)?.label.text

                this.annotations = {
                    text: currentAnnotation,
                    showModal: true,
                    current: {
                        ID: datapoint.ID,
                        text: currentAnnotation
                    }
                }

                Vue.nextTick(() => {
                    this.$refs.annotationInput.focus()
                })
            }
        },
        async editAnnotation() {
            const Annotation = this.annotations.text

            if(Annotation.length > 0) {
                try {
                    await StationValueService.editGraphAnnotation(this.annotations.current.ID, {
                        Annotation
                    })

                    this.reload()
                } catch (err) {
                    console.error(err)
                    this.errorAlert(
                        err.response.data.error
                            ? err.response.data.error
                            : "Die Bemerkung konnte nicht geändert werden."
                    )
                }

                this.annotations.showModal = false
            }
        },
        async removeAnnotation() {
            try {
                await StationValueService.editGraphAnnotation(this.annotations.current.ID, {
                    Annotation: null
                })

                this.annotations.showModal = false
                this.reload()
            } catch (err) {
                console.error(err)
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Die Bemerkung konnte nicht entfernt werden."
                )
            }
        },
        chartLegendClick(chartContext, seriesIndex) {
            this.chart.series[seriesIndex].show = !this.chart.series[seriesIndex]
                .show
            this.checkYAxes()
        },
        chartScrolled(chartContext, { xaxis }) {
            this.setXAxisInfo(xaxis)
            this.appendOutOfRangeValues(xaxis)
        },
        chartBeforeZoom(chartContext, { xaxis }) {
            this.setXAxisInfo(xaxis)
            this.appendOutOfRangeValues(xaxis)
        },
        setXAxisInfo(xaxis) {
            const { min, max } = xaxis
            this.chart.state.xaxis = {
                min,
                max
            }
        },
        resetXAxisInfo() {
            this.setXAxisInfo({ min: null, max: null })
        },
        async exportData(excel = true) {
            let allSeriesData = []
            this.chart.series.map(series => {
                allSeriesData = allSeriesData.concat(series.data)
            })

            allSeriesData.sort((a, b) => moment(a.x).diff(b.x))

            const { min: startDate, max: endDate } = this.chart.state.xaxis
            const isCustomRange = !!(startDate && endDate) // Benutzer hat den Graphen vergrößert oder verschoben

            if(isCustomRange)
                allSeriesData = allSeriesData.filter(data => moment(data.x).isBetween(startDate, endDate))

            let seriesNameList = {}
            this.chart.series.forEach(series => {
                seriesNameList[series.id] = series.name
            })

            let getStationName = id => seriesNameList[id]

            this.confirmDialog(
                {
                    title: "Daten exportieren",
                    message: "Welches Datenformat soll die zu exportierende Datei haben?",
                    confirmText: "Excel",
                    cancelText: "CSV",
                    icon: "download",
                    type: "is-dark"
                },
                async () => {
                    // Excel-Export
                    const schema = [{
                        column: "Messstelle",
                        type: String,
                        value: record => getStationName(record.StationID)
                    }, {
                        column: "Zeitstempel",
                        type: Date,
                        format: "mm/dd/yyyy hh:mm:ss",
                        width: 18,
                        value: record => new Date(record.x)
                    }, {
                        column: "Messwert",
                        type: Number,
                        value: record => +record.y
                    }, {
                        column: "Bemerkung",
                        type: String,
                        value: record => record.Annotation
                    }]

                    await writeXlsxFile(allSeriesData, {
                        schema,
                        fileName: "alflex-trend.xlsx"
                    })
                },
                trigger => {
                    // CSV-Export
                    // Trigger muss abgefragt werden, da der CSV Button eigentlich der Cancel Button ist und die onCancel Funktion auch bei Escape/Klicken außerhalb aufgerufen wird
                    if(trigger === 'button') {
                        const csvArray = [
                            ["Messstelle", "Zeitstempel", "Messwert", "Bemerkung"],
                            ...allSeriesData.map(record => [
                                getStationName(record.StationID),
                                record.x,
                                record.y,
                                record.Annotation
                            ])
                        ]

                        const csvString = csvArray.map(item => item.join(';')).join('\n')
                        window.open(`data:text/csv;charset=utf-8,${encodeURI(csvString)}`, '_blank')
                    }
                }
            )
        },
        appendOutOfRangeValues(xaxis) {
            if (this.firstTimestamp >= xaxis.min && !this.loadOutOfRangeValues) {
                this.loadOutOfRangeValues = true
                this.reloadData(true)
            }
        },
        resetChart() {
            this.resetXAxisInfo()
            this.$refs.graph.resetSeries()
        },
        async getProfileInfo() {
            // Server nach Profilinfos fragen
            var profilePermalink = this.permalink
                ? this.permalink
                : store.state.profile.Permalink
            // Profilinfos nochmal anfordern, damit sie immer up-to-date sind
            const latestProfile = await ProfileService.getLatestProfile(
                profilePermalink
            )
            var permalink = latestProfile.data.Permalink
            if (latestProfile.data) {
                store.commit("setProfile", latestProfile.data)
            } else {
                store.commit("setProfile", {})
            }

            const response = await ProfileService.getAllProfiles()
            store.commit('setProfiles', response.data)

            if (!this.permalink) {
                if (store.state.profiles.length > 0) {
                    // Es ist mindestens ein Profil verfügbar
                    router.push({ name: "dashboard", params: { permalink: permalink } })
                } else {
                    // Kein Profil verfügbar
                    this.errorAlert("Es konnte kein Profil gefunden werden.")
                    this.profileButton = true
                    this.loading = true
                    return
                }
            } else {
                if (this.permalink != latestProfile.data.Permalink) {
                    // Veralteter Permalink von gelöschtem Profil
                    router.push({
                        name: "dashboard",
                        params: { permalink: latestProfile.data.Permalink }
                    })
                }
            }

            this.reload(true, true)
        },
        async getStationInfo() {
            const response = await StationService.getSelectedStations()
            this.selected = response.data
            for (let s of this.selected) {
                s.enabled = true
            }
        },
        async toggleSeries(series) {
            this.checkYAxes()

            this.$refs.graph.toggleSeries(series.name)
        },
        openStationDetails(stationID) {
            router.push({ name: 'stationDetails', params: { ID: stationID } })
        },
        checkYAxes() {
            let yAxes = this.chart.options.yaxis

            const shownUniqueYAxes = yAxes.filter(yaxis => {
                const correspondingSeries = this.chart.series.find(series => series.name === yaxis.seriesName)
                return correspondingSeries.show && yaxis.show && !yaxis.cloned
            })

            const clonedYAxes = yAxes.some(yaxis => yaxis.cloned)

            if(shownUniqueYAxes.length === 1 && !clonedYAxes) {
                yAxes.push({
                    ...shownUniqueYAxes[0], // cloned from prior yaxis
                    opposite: !shownUniqueYAxes[0].opposite,
                    cloned: true
                })
            } else if(shownUniqueYAxes.length > 1 && clonedYAxes) {
                // remove cloned yaxis
                yAxes = yAxes.filter(yaxis => !yaxis.cloned)
            }

            // Verhindern, dass zwei Y-Achsen auf der gleichen Seite sind
            const oppositeValues = shownUniqueYAxes.map(yaxis => yaxis.opposite)
            if(shownUniqueYAxes.length === 2 && oppositeValues[0] === oppositeValues[1]) {
                // Zweite Y-Achse auf die andere Seite verschieben
                yAxes[yAxes.indexOf(shownUniqueYAxes[1])].opposite = !shownUniqueYAxes[1].opposite
            }

            this.chart = {
                ...this.chart,
                ...{
                    options: {
                        yaxis: yAxes
                    }
                }
            }
        },
        async getGraphValues(custom = false) {
            const options = {
                annotations: {
                    points: []
                },
                colors: [],
                yaxis: [],
                stroke: {
                    width: 3,
                    curve: []
                }
            }

            const series = []

            let response = {}
            if (custom) {
                response = await StationValueService.getCustomGraphValues(custom)
            } else {
                response = await StationValueService.getGraphValues()
            }

            // Timestamp des ersten Wertes berechnen
            let firstTimestamps = []

            for (let station of this.selected) {
                // todo: only add stations where data values exist
                const yAxisObj = {
                    show: !station.YAxisHidden,
                    seriesName: station.Name,
                    stationID: station.ID,
                    opposite: this.selected.indexOf(station) >= Math.ceil(this.selected.length / 2),
                    decimalsInFloat: station.Decimals,
                    ...(station.Decimals === 0 && { tickAmount: 1 }),
                    ...(station.YAxisMin && { min: +station.YAxisMin }),
                    ...(station.YAxisMax && { max: +station.YAxisMax }),
                    title: {
                        text: station.YAxisName,
                        style: {
                            color: station.Color,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 900,
                            cssClass: "apexcharts-yaxis-title"
                        }
                    },
                    labels: {
                        offsetX: 14,
                        offsetY: -5
                    },
                    tooltip: {
                        enabled: true
                    }
                }

                options.yaxis.unshift(yAxisObj)
            }

            // if only one yaxis in total, display yaxis on both sides
            if(options.yaxis.length === 1) {
                const yAxisCopy = { ...options.yaxis[0] }
                yAxisCopy.opposite = !yAxisCopy.opposite
                yAxisCopy.singleCopy = true
                options.yaxis.unshift(yAxisCopy)
            }

            // Reihenfolge der opposite y axes umdrehen
            let yAxes = options.yaxis.filter(yaxis => !yaxis.opposite)
            const oppositeYAxes = options.yaxis.filter(yaxis => yaxis.opposite)
            oppositeYAxes.reverse()
            yAxes = yAxes.concat(oppositeYAxes)
            options.yaxis = yAxes

            // todo: zuerst Y-Achsen hinzufügen und dann die zugehörigen Stations, damit Reihenfolge passt und der Index übereinstimmt (sonst kommt es zu Fehlern wenn eine Kurve ein- oder ausgeblendet werden soll)
            for(let yaxis of options.yaxis.filter(yaxis => !yaxis.singleCopy)) {
                const station = this.selected.find(station => station.ID === yaxis.stationID)

                // data from stations
                let stationValues = response.data.values
                    .filter(dataset => {
                        return (
                            dataset.StationID === station.ID &&
                            (custom || (this.loadOutOfRangeValues || Number(dataset.DateDifference) >= 0))
                        ) // bei benutzerdefiniertem Zeitbereich oder falls outOfRange aktiv ist werden alle vom Server zurückgegebenen Daten geladen, ansonsten nur die jeweils relevanten Datensätze
                    })
                    .map(dataset => {
                        const { ID, Timestamp, Value, StationID, Annotation } = dataset

                        if (StationID === 11) console.log("Treffer", Value)

                        return {
                            ID,
                            x: Timestamp,
                            y: Number(station.Offset) !== 0 ? (Number(Value) + Number(station.Offset)) : Value,
                            StationID,
                            Annotation
                        }
                    })

                if (stationValues.length > 0) {
                    firstTimestamps.push(Number(moment(stationValues[0].x).format("x")))
                }

                // get station stats
                let stationStats = response.data.stats.find(row => row.StationID === station.ID)

                // push series
                series.push({
                    id: station.ID,
                    name: station.Name,
                    label: station.Label,
                    color: station.Color,
                    data: stationValues,
                    decimals: station.Decimals,
                    stats: stationStats,
                    show: true
                })

                // push curve types
                options.stroke.curve.push(station.IsStepLine === 1 ? 'stepline' : 'straight')

                // push colors
                options.colors.push(station.Color)
            }

            // Erst dann ausführen, wenn Graph bereits gemountet ist
            if(this.hasChartMounted) {
                // falls insgesamt nur eine angezeigte Y-Achse, diese auf beiden Seiten anzeigen
                this.checkYAxes()
            }

            firstTimestamps.sort((a, b) => a - b)
            this.firstTimestamp = firstTimestamps[0]

            this.updateChartConfig({ series })
            this.updateChartConfig({ options })

            Vue.nextTick(() => {
                this.setChartAnnotations()
            })
        },
        setChartAnnotations() {
            if(this.$refs.graph && this.chart.options.annotations.points.length > 0) {
                this.$refs.graph.clearAnnotations()
            }

            const annotations = []

            this.chart.series.forEach(series => {
                series.data.filter(dataset => dataset.Annotation?.length > 0).map(dataset => {
                    const { ID, x, y, Annotation } = dataset
                    const yAxisIndex = this.chart.options.yaxis.findIndex(yaxis => yaxis.stationID === dataset.StationID)
                    if(yAxisIndex >= 0) {
                        annotations.push({
                            ID,
                            x: +moment(x).format("x"),
                            y,
                            yAxisIndex,
                            marker: {
                                size: 6,
                                fillColor: "#363636",
                                strokeColor: "#FFF",
                                radius: 1
                            },
                            label: {
                                show: true,
                                borderColor: '#363636',
                                style: {
                                    color: "#FFF",
                                    background: "#363636",
                                    fontSize: "16px"
                                },
                                text: Annotation
                            }
                        })
                    } else {
                        console.error("Bemerkung konnte nicht geladen werden, da die zu dem Messwert zugehörige Y-Achse nicht gefunden werden konnte.")
                    }
                })
            })

            const options = this.chart.options
            options.annotations.points = annotations

            if(annotations.length > 0) {
                this.updateChartConfig({ options })
            }
        },
        updateChartConfig(obj) {
            this.chart = {
                ...this.chart,
                ...obj
            }
        },
        chartMounted() {
            this.checkYAxes()
            this.hasChartMounted = true
        },
        async reloadData(silent = false) {
            this.loading = true
            this.resetXAxisInfo()
            await this.getStationInfo()
            await this.getGraphValues()
            this.loading = false
            if(!silent) this.successAlert(this.tabularDisplay ? "Die Daten wurden neu geladen." : "Der Graph wurde neu geladen.")
        },
        openCustomRangeDialog() {
        // set default values if customRange has not yet been enabled and open dialog
            if (!this.customRange.enabled) {
                let from = moment().subtract(12, "hours")
                this.customRange.from = {
                    date: from.toDate(),
                    hours: Number(from.format("HH")),
                    minutes: Number(from.format("mm"))
                }

                let to = moment()
                this.customRange.to = {
                    date: to.toDate(),
                    hours: Number(to.format("HH")),
                    minutes: Number(to.format("mm"))
                }
            }

            this.customRange.showModal = true
        },
        async setCustomRange() {
            this.customRange.isLoading = true
            let { from, to } = this.customRange
            let fromDT = new Date(from.date)
            let toDT = new Date(to.date)

            fromDT.setHours(from.hours)
            fromDT.setMinutes(from.minutes)

            toDT.setHours(to.hours)
            toDT.setMinutes(to.minutes)

            if (fromDT < toDT) {
                this.setXAxisInfo({ min: +moment(fromDT).format("x") , max: +moment(toDT).format("x") })
                await this.getGraphValues({
                    from: moment.utc(fromDT).format("YYYY-MM-DD HH:mm:ss"),
                    to: moment.utc(toDT).format("YYYY-MM-DD HH:mm:ss")
                })

                this.customRange.showModal = false
                this.customRange.enabled = true
            } else {
                this.errorAlert("Bitte überprüfen Sie die eingegebenen Daten.")
            }
            this.customRange.isLoading = false
        },
        resetCustomRange() {
            // reset customRange settings
            this.customRange = {
                enabled: false,
                showModal: false,
                isLoading: false,
                from: {
                    date: new Date(),
                    hours: 0,
                    minutes: 0
                },
                to: {
                    date: new Date(),
                    hours: 0,
                    minutes: 0
                }
            }

            this.resetXAxisInfo()
            this.reload()
        }
    }
}
</script>

<style scoped>
.fade-enter-active {
    transition: opacity .3s;
}
.fade-leave-active {
    transition: opacity .5s;
    transition-delay: 1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-faster-enter-active {
    transition: opacity .3s;
}
.fade-faster-leave-active {
    transition: opacity .4s;
    transition-delay: .1s;
}
.fade-faster-enter,
.fade-faster-leave-to {
    opacity: 0;
}


.modal-card-body .field label.label {
    color: #000 !important;
}

.modal-card-title {
    font-family: Inter;
    font-weight: 700;
}
</style>
<style>
.modal-card-body .field label.label {
    color: #000 !important;
}
</style>

<style lang="scss">
@import "~bulma/sass/utilities/_all";
$label-color: #000 !important;
</style>
