import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { sync } from "vuex-router-sync"
import store from "./store"
import "moment"
import VueApexCharts from "vue-apexcharts"

import Buefy from "buefy"
import "bulma-helpers/css/bulma-helpers.min.css"

import VueCookies from "vue-cookies"

import VueFeatherIconPack from "./components/VueFeatherIconPack.vue"
import { methods } from "@/methods"
import "./filters"

Vue.component("VueFeatherIconPack", VueFeatherIconPack)

Vue.use(Buefy, {
    defaultIconComponent: "vue-feather-icon-pack",
    defaultIconPack: "ft",
    customIconPacks: {
        ft: {
            sizes: {
                default: "1.5x",
                "is-small": "1x",
                "is-medium": "2x",
                "is-large": "3x"
            },
            iconPrefix: "",
            internalIcons: {
                information: "info",
                "check-circle": "checkmark-circle",
                alert: "alert-triangle",
                "alert-circle": "alert-circle",
                "arrow-up": "arrow-up",
                "chevron-right": "chevron-right",
                "chevron-left": "chevron-left",
                "chevron-down": "chevron-down",
                eye: "eye",
                "eye-off": "eye-off",
                "menu-down": "arrow-down",
                "menu-up": "arrow-up",
                "download": "download"
            }
        }
    }
})

Vue.use(VueCookies)

Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component("ApexChart", VueApexCharts)

Vue.mixin({
    methods: methods
})

Vue.directive("title", {
    inserted: (el, binding) => (document.title = binding.value),
    update: (el, binding) => (document.title = binding.value)
})

sync(store, router)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
