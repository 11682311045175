var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ibnSidebar',[(_vm.showAddButton)?_c('button',{staticClass:"button has-margin-bottom-20 is-success",on:{"click":function($event){return _vm.addRow()}}},[_c('plus-icon',{staticClass:"has-margin-right-5"}),_vm._v("Hinzufügen ")],1):_vm._e(),_c('div',[(_vm.tab === 'user' && (!_vm.$store.state.auth || _vm.$store.state.user.Rights >= 300))?_c('button',{staticClass:"button is-info is-small has-margin-bottom-10",on:{"click":function($event){return _vm.addApiToken()}}},[_vm._v(" API-Token hinzufügen ")]):_vm._e()]),(_vm.filterFields.length > 0)?_c('div',{staticClass:"has-margin-bottom-20"},[_c('b-input',{attrs:{"placeholder":_vm.searchInputText},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(_vm.data[0])?_c('b-table',{key:_vm.updateTable,ref:"table",staticClass:"has-margin-bottom-20",attrs:{"id":"startupTable","data":_vm.filterFields.length > 0 ? _vm.filteredData : _vm.data,"selected":_vm.selected,"mobile-cards":"","striped":""}},[_vm._l((_vm.$store.state.ibn.columns[_vm.tab].filter(
                item => !item.hide
            )),function(column){return _c('b-table-column',{key:column.field,attrs:{"field":column.field,"label":column.label},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(column.type === 'checkbox')?_c('b-checkbox',{staticStyle:{"vertical-align":"middle"},attrs:{"value":!!props.row[column.field],"disabled":""}}):_c('span',[(column.type === 'date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateTimeSec")(props.row[column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.row[column.field])+" ")])])]}}],null,true)})}),(_vm.tab === 'station')?_c('b-table-column',{attrs:{"label":"Letzter Messwert"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.LastTimestamp)?_c('span',[_vm._v(_vm._s(_vm._f("formatDateTimeSec")(props.row.LastTimestamp)))]):_c('b-skeleton',{attrs:{"animated":true,"width":"70%"}})]}}],null,false,1124709580)}):_vm._e(),(_vm.tab === 'user')?_c('b-table-column',{attrs:{"label":"Kunde"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.Customer ? props.row.Customer.Name : '')+" ")]}}],null,false,1699556501)}):_vm._e(),(_vm.tab === 'user')?_c('b-table-column',{attrs:{"label":"Sitzungen"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"button is-small is-info has-text-white",attrs:{"to":{
                    name: 'startup',
                    params: { tab: 'session', filter: props.row.Email },
                }}},[_vm._v(" "+_vm._s(props.row.Sessions ? props.row.Sessions.length : '')+" ")])]}}],null,false,3769717558)}):_vm._e(),_c('b-table-column',{staticClass:"has-text-right",staticStyle:{"width":"100%"},attrs:{"label":"Aktionen","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"has-whitespace-nowrap has-text-right is-inline-block"},[(
                        _vm.tab === 'user' &&
                            props.row.Email &&
                            props.row.Email.split('@')[1] === 'api.albertfrey.de'
                    )?_c('span',[_c('key-icon',{staticClass:"has-margin-right-5",attrs:{"size":"1.5x","stroke-width":"2.5"}})],1):_c('span',[(_vm.tab === 'opcserver')?_c('button',{staticClass:"button is-success is-small has-margin-right-5",on:{"click":function($event){return _vm.downloadOPCServer(props.row)}}},[_c('download-icon',{attrs:{"size":"1.5x","stroke-width":"2.5"}})],1):_vm._e(),(
                            _vm.$store.state.ibn.columns[_vm.tab].filter(
                                (item) => !item.editDisabled
                            )[0]
                        )?_c('button',{staticClass:"button is-info is-small",attrs:{"title":"Bearbeiten"},on:{"click":function($event){return _vm.editRow(props.row.ID)}}},[_c('edit-2-icon',{attrs:{"size":"1.5x","stroke-width":"2.5"}})],1):_vm._e(),(
                            _vm.tab === 'user' &&
                                props.row.Sessions &&
                                props.row.Sessions.length > 0 &&
                                props.row.Rights < _vm.$store.state.user.Rights
                        )?_c('button',{staticClass:"button is-danger is-small has-margin-left-5",attrs:{"title":"Sitzungen entfernen"},on:{"click":function($event){return _vm.deleteUserSessions(props.row)}}},[_c('slash-icon',{attrs:{"size":"1.5x","stroke-width":"2.5"}})],1):_vm._e()]),_c('button',{staticClass:"button is-danger is-small has-margin-left-5",attrs:{"title":"Löschen"},on:{"click":function($event){return _vm.deleteRow(props.row.ID)}}},[_c('trash-2-icon',{attrs:{"size":"1.5x","stroke-width":"2.5"}})],1)])]}}],null,false,1170230826)})],2):_c('b-notification',{staticClass:"has-margin-top-20 has-padding-bottom-20",attrs:{"closable":false,"role":"alert","type":"is-danger"}},[_c('div',{staticClass:"is-flex flex-row align-items-center"},[_c('alert-circle-icon',{staticClass:"has-margin-right-10"}),_vm._v("Keine Daten verfügbar ")],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }