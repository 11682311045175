<template>
    <div class="is-full-width">
        <!-- Navbar Section -->
        <Navbar>
            <template slot="icon">
                <tool-icon class="has-margin-right-10" />
            </template>
            <template slot="title">
                Inbetriebnahme
            </template>
            <b-navbar-item class="has-margin-right-10" tag="div">
                <BackButton back-to-home />
            </b-navbar-item>
        </Navbar>

        <!-- Sidebar -->
        <div class="sidebar-page">
            <section class="sidebar-layout">
                <b-sidebar
                    fullheight
                    :mobile="$store.state.navbarActive ? 'fullwidth' : 'hide'"
                    open
                    position="static"
                    type="is-dark"
                >
                    <div class="p-1">
                        <b-menu class="is-custom-mobile align-items-start has-text-left">
                            <b-menu-list>
                                <b-menu-item
                                    :to="{ name: 'startup', params: { tab: 'opcserver' } }"
                                    label="OPC Server"
                                    tag="router-link"
                                />
                                <b-menu-item
                                    :to="{ name: 'startup', params: { tab: 'station' } }"
                                    label="Messstellen"
                                    tag="router-link"
                                />
                                <b-menu-item
                                    v-if="!$store.state.auth || $store.state.user.Rights >= 300"
                                    :to="{ name: 'startup', params: { tab: 'customer' } }"
                                    label="Kunden"
                                    tag="router-link"
                                />
                                <b-menu-item
                                    :to="{ name: 'startup', params: { tab: 'user' } }"
                                    label="Benutzer"
                                    tag="router-link"
                                />
                                <b-menu-item
                                    :to="{ name: 'startup', params: { tab: 'session' } }"
                                    label="Sitzungen"
                                    tag="router-link"
                                />
                                <b-menu-item
                                    v-if="!$store.state.auth || $store.state.user.Rights >= 300"
                                    :to="{ name: 'startupSettings' }"
                                    label="Einstellungen"
                                    tag="router-link"
                                />
                            </b-menu-list>
                        </b-menu>
                    </div>
                </b-sidebar>

                <div class="is-full-width">
                    <!-- Content -->
                    <div
                        class="container has-margin-top-20 has-padding-bottom-20 has-margin-left-50-tablet has-margin-right-50-tablet has-margin-left-20 has-margin-right-20"
                    >
                        <slot />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Navbar from "./Navbar"
import BackButton from "./BackButton"
import { ToolIcon } from "vue-feather-icons"

export default {
    name: "IbnSidebar",
    components: {
        Navbar,
        BackButton,
        ToolIcon
    }
}
</script>

<style lang="scss">
ul.menu-list > li {
  :hover {
    color: #bdc3c7 !important;
    background: transparent !important;
  }
  a {
    display: flex !important;
    font-family: Inter;
    font-weight: 700;
  }
  .icon {
    margin-right: 0.3rem;
  }
}

.b-sidebar .sidebar-content {
    width: 150px !important;
}
</style>
