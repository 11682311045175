import Vue from 'vue'
import moment from 'moment'

// add global filters
Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YY HH:mm')
    }
})
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YY')
    }
})
Vue.filter('formatDateTimeSec', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YY HH:mm:ss')
    }
})
Vue.filter('formatDecimal', function (value) {
    if (value) {
        return Number(value).toLocaleString('de-DE')
    } else { return 0 }
})
Vue.filter('formatDecimalToFixed', function (value, decimals) {
    if (value) {
        return Number(value).toFixed(decimals).toLocaleString('de-DE')
    } else { return 0 }
})
Vue.filter('boolToStr', function (value) {
    if (value === 1) {
        return 'Ja'
    } else {
        return 'Nein'
    }
})
Vue.filter('formatPercentage', function (value) {
    if (value) {
        return (Number(value) * 100).toLocaleString('de-DE')
    }
})
Vue.filter('formatStationName', function (value) {
    if (!value) return "Station"

    let stationName = ""

    if (value.Name) stationName += value.Name
    if (value.name) stationName += value.name

    if (value.Label) stationName += ` [${value.Label}]`
    if (value.label) stationName += ` [${value.label}]`
  
    return stationName
})