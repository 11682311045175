<template>
    <div>
        <!-- Spinner -->
        <b-loading
            :active="showSpinner"
            :can-cancel="false"
            :is-full-page="true"
        >
            <div class="loading-icon" />
            <h1 class="subtitle loading-title is-white is-size-3">
                ALFlex Trend
            </h1>
        </b-loading>
        <div v-show="!showSpinner" id="app">
            <!-- Router View -->
            <div
                class="is-full-width"
                style="display: flex; flex: 1"
            >
                <router-view v-title="title" />
            </div>

            <footer
                class="footer"
                style="font-family: Inter; font-size: 1.2rem; z-index: 100"
            >
                <div class="content">
                    <p
                        style="float: left; margin-bottom: 0; color: inherit; font-weight: 800;"
                    >
                        ALFlex Trend
                    </p>
                    <p style="float: right">
                        <router-link
                            :to="{ name: 'help' }"
                            class="nav-link has-margin-right-15"
                            style="color: inherit"
                        >
                            Hilfe
                        </router-link>
                        <router-link
                            v-if="!$store.state.auth || $store.state.token && $store.state.user.Rights >= 200"
                            :to="{ name: 'startup', params: { tab: 'opcserver' } }"
                            class="nav-link"
                            style="color: inherit"
                        >
                            Inbetriebnahme
                        </router-link>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import AuthService from "@/services/AuthService"
import SettingService from "./services/SettingService"

export default {
    data() {
        return {
            updateTitle: 0
        }
    },
    computed: {
        title() {
            this.updateTitle
            if (["dashboard", "settings"].includes(this.$router.currentRoute.name) && this.$store.state.profile.Name) {
                return `${this.$store.state.profile.Name} · ALFlex Trend`
            } else if (this.$router.currentRoute.name === "userSettings" && this.$store.state.user?.Email) {
                return `${this.$store.state.user.Email} · ALFlex Trend`
            } else if (this.$router.currentRoute.meta?.title) {
                return `${this.$router.currentRoute.meta.title} · ALFlex Trend`
            } else {
                return "ALFlex Trend"
            }
        },
        showSpinner: {
            get() {
                return this.$store.state.loading
            },
            set(newVal) {
                this.$store.commit("setLoading", newVal)
            }
        }
    },
    watch: {
        $route() {
            this.closeAllAlerts()
            this.updateTitle++
        }
    },
    async beforeMount() {
        this.showLoadingScreen()
        this.$router.beforeEach((to, from, next) => {
            this.showLoadingScreen()
            next()
        })

        // check whether grafana is enabled or not
        const response = await SettingService.getSettings()
        const { Value: grafanaBaseURL } = response.data.find(item => item.Key === 'GRAFANA_BASE_URL')
        const isGrafanaActive = !!(grafanaBaseURL && grafanaBaseURL.length > 0)
        this.$store.commit("setGrafanaActive", isGrafanaActive)

        // set auth according to server response
        try {
            const response = await AuthService.getAuthStatus()
            if (response.data) {
                this.checkAuthStatus(response.data.authEnabled)
            }
        } catch (e) {
            if (e.response.data) {
                this.checkAuthStatus(e.response.data.authEnabled)
            }

            // redirect to login
            this.$router.push({ name: "login" })
        }
    }
}
</script>

<style>
#app {
  min-height: 100vh;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

nav.sidebar a.router-link-exact-active {
  color: #42b983;
}

a:hover {
  text-decoration: none;
}
</style>

<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .sidebar-layout {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #363636;
$primary-invert: findColorInvert($primary);
$text: #fff;
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$secondary: $twitter;
$secondary-invert: findColorInvert($primary);
$body-background-color: #212121;
$footer-background-color: $primary;
$footer-color: $text;
$footer-padding: 1.5rem;
//$dropdown-content-background-color: $body-background-color;
$dropdown-item-color: #212121;
$navbar-dropdown-background-color: #212121;
$navbar-background-color: #363636;
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "secondary": (
    $secondary,
    $secondary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "twitter": (
    $twitter,
    $twitter-invert,
  ),
);

// Links
//$link: #FFF;
//$link-active: #FFF;
$label-color: rgba(255, 255, 255, 0.9);

$link-invert: $primary-invert;
$link-focus-border: $primary;
$link-focus: #000;

.control.has-icons-left .input:focus ~ .icon {
  color: $grey-dark !important;
}

// table
$table-color: #FFF;
$table-head-cell-color: $table-color;
$table-background-color: rgb(0, 209, 178);
$table-body-background-color: rgb(54, 54, 54);
$table-cell-border: 0;
$table-cell-padding: 0.7em;
$table-striped-row-even-background-color: rgb(62, 62, 62);

ul.menu-list a.router-link-active {
  font-weight: 900;
  color: $success !important;
}

span.control-label {
  color: $label-color;
}

$family-primary: Inter;
$family-secondary: Quicksand;

$loading-background: #212121;

$panel-block-hover-background-color: #363636;

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.8s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<style>
body {
  overflow-x: hidden;
}

.dropdown-content {
  background-color: #444444;
  color: #ffffff !important;
}

a.dropdown-item {
  color: #ffffff;
}

a.dropdown-item:hover {
  background: initial;
  color: rgba(255, 255, 255, 0.85);
}

/* PANEL  */
.panel {
  padding-bottom: 0.2rem;
  margin-bottom: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 0.8em 5em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.panel-heading {
  font-family: Quicksand;
  font-weight: 700;
  margin-bottom: 0.2rem;
  background-color: #00d1b2;
  color: #fff;
  border-radius: 6px 6px 0 0;
  font-size: 1.25em;
  line-height: 1.25;
  padding: 0.75em 0.7em;
}

.dropdown-item,
.panel-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button,
input,
article.notification,
.snackbar {
  font-family: Inter;
}

article.notification > .media > .media-content > div,
.snackbar {
  font-weight: 600;
}

.panel-block:not(:last-child),
.panel-tabs:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.panel-tabs {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
}

article p {
  margin: 0;
  padding: 0;
  margin-block-start: 0em;
  margin-block-end: 0.6em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.panel.is-primary .panel-tabs a.is-active {
  border-bottom-color: #00d1b2;
}

.panel-tabs a {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: 0 0.5em 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
}

article.panel {
  line-height: 1.5;
}

a.panel-block {
  font-weight: 300;
  color: #fff;
  background: #363636;
}

.panel-block {
    color: #fff;
}

.panel-tabs a:not([href]):not([tabindex]) {
  color: #fff;
}

.dark-panel {
  box-shadow: 0 0.5em 1em -0.125em #363636, 0 0 0 0.1px #363636;
  background: #363636 !important;
}
.dark-panel .panel-block:not(:last-child),
.dark-panel .panel-tabs:not(:last-child) {
  border-bottom: 0.02px solid #00d1b2;
}

.panel.no-border .panel-block:not(:last-child),
.panel.no-border .panel-tabs:not(:last-child) {
  border-bottom: 0;
}

a.panel-block:hover {
  color: #fff;
}

.borderless-input {
  border: none transparent;
  outline: none;
  background: transparent;
}

.headline-section {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  color: #fff;
  font-size: 2.4rem;
}

.is-inline-block {
  display: inline-block !important;
}

.is-vertically-centered,
.media-content > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.has-whitespace-nowrap {
  white-space: nowrap !important;
}

.snackbar.is-bottom {
  margin-bottom: 4rem;
  position: static;
}

.is-rounded-button {
  border-radius: 999px !important;
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 1023px) {
  .navbar-item {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  button.is-dark,
  a.is-dark {
    background-color: #00d1b2 !important;
  }
}

.loading-title {
  z-index: 100;
  font-weight: 900;
  margin-top: 10rem;
}

.loading-icon {
  position: absolute !important;
}

.dialog .modal-card .modal-card-body .media-content .field {
  margin-top: 0;
  display: block;
}

.dialog .modal-card .modal-card-body .media-content .field .control {
  width: 100%;
}

.custom-scroll .dropdown-content {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

input.colorInput {
    cursor: pointer;
}

.dark-button, .dark-button:hover, .dark-button:active, .dark-button:focus {
    color: #FFF;
    border-color: transparent;
}

.dark-button {
    background: rgb(66, 66, 66);
}

.dark-button:hover {
    background: rgb(62, 62, 62);
}

.dark-button:active {
    background: rgb(70, 70, 70);
}
</style>
