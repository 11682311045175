<template>
    <ibnSidebar>
        <b-input
            v-model="searchTerm"
            placeholder="Nach Schlüssel suchen..."
        />

        <b-table
            v-if="settings && settings[0]"
            ref="table"
            :data="settings"
            class="has-margin-top-20 has-margin-bottom-20"
            mobile-cards
            striped
        >
            <b-table-column
                v-for="column in columns"
                :key="column.field"
                v-slot="props"
                :field="column.field"
                :label="column.label"
            >
                <div
                    v-if="column.field === 'Value' && edit.rowID === props.row.ID"
                    class="columns"
                    @keydown.esc="leaveEditMode"
                >
                    <div class="column">
                        <form @submit.prevent="save(props.row)">
                            <!-- icon-right="check" -->
                            <b-field
                                custom-class="home-icon"
                                type="is-success"
                            >
                                <b-input
                                    v-model="edit.value"
                                    :placeholder="props.row[column.field]"
                                    class="is-small"
                                    type="text"
                                />
                            </b-field>
                        </form>
                    </div>
                    <div class="column is-vertically-centered">
                        <RoundedButton type="is-success" @click.native="save(props.row)">
                            <check-icon stroke-width="3" />
                        </RoundedButton>
                        <RoundedButton type="is-danger" @click.native="leaveEditMode">
                            <x-icon stroke-width="3" />
                        </RoundedButton>
                    </div>
                </div>
                <div v-else class="is-vertically-centered">
                    {{ props.row[column.field] }}
                    <RoundedButton
                        v-if="column.field === 'Value' && edit.rowID === null"
                        class="has-margin-left-10"
                        type="is-info"
                        @click.native="editRow(props.row.ID, props.row[column.field])"
                    >
                        <edit-2-icon />
                    </RoundedButton>
                </div>
            </b-table-column>
        </b-table>

        <button class="button is-primary" @click="sendTestEmail">
            <mail-icon class="has-margin-right-5" />Test E-Mail senden
        </button>
    </ibnSidebar>
</template>

<script>
import ibnSidebar from "../components/ibnSidebar"
import SettingService from "../services/SettingService"
import { CheckIcon, Edit2Icon, XIcon, MailIcon } from "vue-feather-icons"
import RoundedButton from "@/components/RoundedButton"
import NotificationService from '../services/NotificationService'

const nullableFields = ["GRAFANA_BASE_URL"]

export default {
    name: "StartupSettings",
    components: {
        RoundedButton,
        ibnSidebar,
        XIcon,
        Edit2Icon,
        CheckIcon,
        MailIcon
    },
    data() {
        return {
            searchTerm: null,
            settings: [],
            columns: [
                {
                    field: "Key",
                    label: "Schlüssel",
                    searchable: true
                },
                {
                    field: "User",
                    label: "Benutzer"
                },
                {
                    field: "Value",
                    label: "Wert"
                }
            ],
            edit: {
                value: null,
                rowID: null
            }
        }
    },
    watch: {
        searchTerm() {
            console.log(this.searchTerm)
            if (!this.$refs.table.filters.Key) this.$refs.table.filters = { Key: "" }

            this.$refs.table.filters.Key = this.searchTerm
            console.log(this.$refs.table.filters)
        }
    },
    async mounted() {
        await this.reload()
        this.hideLoadingScreen()
    },
    methods: {
        async reload() {
            try {
                const response = await SettingService.getSettings()
                this.settings = response.data
            } catch (err) {
                console.log(err)
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            } finally {
                this.hideLoadingScreen()
            }
        },
        editRow(ID, value) {
            this.edit.value = value
            this.edit.rowID = ID
        },
        async save(item) {
            if (nullableFields.includes(item.Key) || this.edit.value) {
                await SettingService.editSetting(item.ID, {
                    Value: this.edit.value
                })

                if(item.Key === 'GRAFANA_BASE_URL')
                    this.$store.commit("setGrafanaActive", !!(this.edit.value && this.edit.value.length > 0))

                this.leaveEditMode()
                this.reload()
            }
        },
        leaveEditMode() {
            this.edit = {
                value: null,
                rowID: null
            }
        },
        async sendTestEmail() {
            try {
                const response = await NotificationService.sendTestEmail()
                if(response.data?.success) {
                    this.successAlert("E-Mail wurde erfolgreich gesendet.")
                } else {
                    this.errorAlert(response.data?.error || "E-Mail konnte nicht gesendet werden.")
                }
            } catch(error) {
                console.error(error)
                this.errorAlert(error.response.data?.error || "E-Mail konnte nicht gesendet werden.")
            }
        }
    }
}
</script>

<style scoped></style>
