import Api from "@/services/Api"

export default {
    getOPCServer() {
        return Api().get("/opcserver")
    },
    showOPCServer(ID) {
        return Api().get(`/opcserver/${ID}`)
    },
    addOPCServer(params) {
        return Api().post("/opcserver", params)
    },
    editOPCServer(ID, params) {
        return Api().put(`/opcserver/${ID}`, params)
    },
    deleteOPCServer(ID) {
        return Api().delete(`/opcserver/${ID}`)
    }
}
