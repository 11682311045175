<template>
    <b-dropdown
        :max-height="310"
        :scrollable="true"
        aria-role="list"
        class="custom-scroll"
        position="is-bottom-left"
    >
        <button
            slot="trigger"
            slot-scope="{ active }"
            class="button is-info"
            style="padding-right: 10px"
        >
            <users-icon class="has-margin-right-5" /><span
                style="font-weight: 600"
            >{{ $store.state.profile.Name || 'Kein Profil verfügbar' }}</span>
            <chevron-up-icon v-if="active" />
            <chevron-down-icon v-else />
        </button>
        <div
            v-for="profile in $store.state.profiles.filter(
                profile => profile.ID !== $store.state.profile.ID
            )"
            :key="profile.ID"
        >
            <b-dropdown-item
                v-if="profile"
                aria-role="listitem"
                @click="setSelectedProfile(profile)"
            >
                {{ profile.Name }}
            </b-dropdown-item>
        </div>
        <hr
            v-if="$store.state.profiles.length > 1"
            class="dropdown-divider"
            style="background-color: #696969"
        >
        <b-dropdown-item aria-role="listitem" @click="createProfile">
            <user-plus-icon class="has-margin-right-5" />Neues
            Profil erstellen
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>

import {
    UsersIcon,
    UserPlusIcon,
    ChevronUpIcon,
    ChevronDownIcon
} from "vue-feather-icons"

import ProfileService from '../services/ProfileService'

export default {
    name: "ProfileDropdown",
    components: {
        UsersIcon,
        UserPlusIcon,
        ChevronUpIcon,
        ChevronDownIcon
    },
    methods: {
        async createProfile() {
            this.promptDialog(
                {
                    title: "Neues Profil erstellen",
                    placeholder: "Profilname",
                    maxlength: 50
                }, (async Name => {
                    // Profil erstellen, wenn ein Profilname angegeben wurde
                    try {
                        const response = await ProfileService.createProfile({
                            Name
                        })

                        if (response.data.Name) {
                            this.$store.commit("setProfile", response.data)
                            this.$router.push({
                                name: "settings",
                                params: { permalink: response.data.Permalink }
                            }).catch(error => {
                                if (error.name != "NavigationDuplicated") {
                                    throw error
                                }
                            })
                        } else if (response.data.error) {
                            this.errorAlert(response.data.error)
                        }
                    } catch (err) {
                        console.error(err)
                        this.errorAlert(
                            err.response.data.error
                                ? err.response.data.error
                                : "Serververbindung fehlgeschlagen."
                        )
                    }
                }))
        },
        async setSelectedProfile(profile) {
            this.$store.commit("setProfile", profile)
            this.$router
                .push({ name: this.$router.currentRoute.name, params: { permalink: profile.Permalink } })
                .catch(error => {
                    if (error.name != "NavigationDuplicated") {
                        throw error
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>
