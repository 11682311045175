import Api from "@/services/Api"

export default {
    getSessions() {
        return Api().get("/session")
    },
    deleteSession(ID) {
        return Api().delete(`/session/${ID}`)
    }
}
