<template>
    <!-- Header -->
    <div class="block">
        <div
            class="is-flex flex-row align-items-center has-margin-top-5 has-margin-bottom-5"
        >
            <slot class="headline-icon has-margin-right-30" name="icon" />
            <h1
                :class="[
                    fullwidth ? 'is-full-width' : '',
                    'headline-section is-bottom-marginless',
                ]"
            >
                <slot />
            </h1>
            <slot name="body" />
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderComp",
    props: {
        fullwidth: Boolean
    }
}
</script>

<style scoped>
svg {
  width: 1.3em;
  height: 1.3em;
  border: 2px solid #363636;
  background-color: #363636;
  color: #fff;
  padding: 0.3rem;
  font-size: 2rem;
  border-radius: 2rem;
  display: inline-block;
  margin-right: 0.6rem;
}

.headline-section {
  font-family: Inter;
  font-weight: 800;
  font-size: 2rem;
}
</style>
