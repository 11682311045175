import Api from "@/services/Api"

export default {
    getSettings() {
        return Api().get("/setting")
    },
    editSetting(ID, params) {
        return Api().put(`/setting/${ID}`, params)
    }
}
