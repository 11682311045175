import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.localStorage
        })
    ],
    state: {
        profile: {},
        profiles: [],
        token: null,
        user: {},
        session: {},
        auth: false,
        loading: true,
        navbarActive: false,
        rightDescriptions: {
            0: "Kunde · Anwender",
            100: "Kunde · Führungskraft",
            200: "Kunde · Admin",
            300: "Albert Frey · Admin"
        },
        settings: {
            grafana: {
                active: false
            }
        },
        ibn: {
            columns: {
                station: [
                    {
                        field: "Permalink",
                        label: "Permalink",
                        type: "text",
                        editDisabled: true
                    },
                    {
                        field: "Name",
                        label: "Name",
                        type: "text",
                        filter: true
                    },
                    {
                        field: "Label",
                        label: "Label",
                        type: "text",
                        filter: true,
                        allowNull: true
                    },
                    {
                        field: "MinValue",
                        label: "MinValue",
                        type: "number",
                        step: "any",
                        allowNull: true
                    },
                    {
                        field: "MaxValue",
                        label: "MaxValue",
                        type: "number",
                        step: "any",
                        allowNull: true
                    },
                    {
                        field: "MinDelta",
                        label: "MinDelta",
                        type: "number",
                        step: 0.001,
                        allowNull: false
                    },
                    {
                        field: "Decimals",
                        label: "Dezimalstellen",
                        type: "number",
                        allowNull: false,
                        min: 0
                    },
                    {
                        field: "IsStepLine",
                        label: "Stufenlinie",
                        type: "checkbox",
                        allowNull: false
                    }
                ],
                opcserver: [
                    {
                        field: "Active",
                        label: "Aktiv",
                        type: "checkbox"
                    },
                    {
                        field: "Name",
                        label: "Name",
                        type: "text"
                    },
                    {
                        field: "IP",
                        label: "IP",
                        type: "text"
                    },
                    {
                        field: "Port",
                        label: "Port",
                        type: "text"
                    },
                    {
                        field: "DataBlock",
                        label: "Datenbaustein",
                        type: "text"
                    },
                    {
                        field: "Customer.Name",
                        label: "Kunde",
                        type: "text",
                        editDisabled: true,
                        filter: true
                    }
                ],
                customer: [
                    {
                        field: "Name",
                        label: "Name",
                        type: "text"
                    },
                    {
                        field: "AlertMailActive",
                        label: "Grenzwertmeldungen",
                        type: "checkbox"
                    },
                    {
                        field: "AlertMail",
                        label: "E-Mail für Grenzwertmeldungen",
                        type: "text"
                    }
                ],
                user: [
                    {
                        field: "Email",
                        label: "E-Mail",
                        type: "email"
                    },
                    {
                        field: "Password",
                        label: "Passwort",
                        type: "password",
                        editDisabled: true,
                        hide: true
                    },
                    {
                        field: "Rights",
                        label: "Rechte",
                        type: "number",
                        min: 0,
                        max: 300,
                        step: 100
                    },
                    {
                        field: "Customer.Name",
                        label: "Kunde",
                        type: "text",
                        hide: true,
                        editDisabled: true,
                        minRightsToModify: 300
                    }
                ],
                session: [
                    {
                        field: "User.Email",
                        label: "Benutzer",
                        type: "text",
                        editDisabled: true,
                        filter: true
                    },
                    {
                        field: "Device",
                        label: "Gerät",
                        type: "text",
                        editDisabled: true
                    },
                    {
                        field: "Expires",
                        label: "Ablaufdatum",
                        type: "date",
                        editDisabled: true
                    }
                ]
            }
        }
    },
    mutations: {
        setProfiles(state, profiles) {
            state.profiles = profiles
        },
        setProfile(state, newProfile) {
            state.profile = newProfile
        },
        setToken(state, token) {
            state.token = token
        },
        setUser(state, user) {
            state.user = user
            if(user?.Rights) state.ibn.columns.user[2].max = user.Rights
        },
        setAuth(state, auth) {
            state.auth = auth
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setGrafanaActive(state, grafanaActive) {
            state.settings.grafana.active = grafanaActive
        },
        setNavbarActive(state, navbarActive) {
            state.navbarActive = navbarActive
        },
        setSession(state, session) {
            state.session = session
        }
    },
    actions: {},
    modules: {}
})
