<template>
    <ibnSidebar>
        <section>
            <form @submit.prevent="editData()">
                <b-field
                    v-for="column in $store.state.ibn.columns[tab].filter(
                        (item) => !item.editDisabled
                    )"
                    :key="column.field"
                    :label="column.label"
                >
                    <b-checkbox
                        v-if="column.type === 'checkbox'"
                        v-model="data[column.field]"
                        type="is-info"
                    >
                        {{ column.label }}
                    </b-checkbox>
                    <b-input
                        v-else
                        v-model="data[column.field]"
                        :max="isNaN(column.max) ? null : column.max"
                        :min="isNaN(column.min) ? null : column.min"
                        :step="isNaN(column.step) && column.step !== 'any' ? null : column.step"
                        :type="column.type"
                    />
                </b-field>
                <b-field>
                    <button class="button is-info" type="submit">
                        <save-icon class="has-margin-right-5" />Speichern
                    </button>
                    <button
                        class="button is-danger is-outlined has-margin-left-10"
                        @click.prevent="$router.push({ name: 'startup', params: { tab } })"
                    >
                        Abbrechen
                    </button>
                </b-field>
            </form>
        </section>
    </ibnSidebar>
</template>

<script>
import OPCServerService from "../services/OPCServerService"
import StationService from "../services/StationService"
import ibnSidebar from "../components/ibnSidebar"
import AuthService from "../services/AuthService"
import CustomerService from "../services/CustomerService"
import { SaveIcon } from "vue-feather-icons"

export default {
    name: "StartupEdit",
    components: {
        ibnSidebar,
        SaveIcon
    },
    props: {
        id: Number,
        tab: String
    },
    data() {
        return {
            data: []
        }
    },
    mounted() {
        this.reload()
        this.hideLoadingScreen()
    },
    methods: {
        async reload() {
            try {
                switch (this.tab) {
                case "station":
                    await this.showStation()
                    break
                case "opcserver":
                    await this.showOPCServer()
                    break
                case "customer":
                    await this.showCustomer()
                    break
                case "user":
                    await this.showUser()
                    break
                default:
                    this.$router.push({ name: "startup" })
                }

                const checkBoxFields = this.$store.state.ibn.columns[this.tab].filter(
                    field => field.type === "checkbox"
                )
                checkBoxFields.forEach(field => {
                    this.$set(this.data, field.field, !!this.data[field.field])
                })
                console.log(checkBoxFields)
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            } finally {
                if (this.data.id) {
                    delete this.data.id
                }
            }
        },
        async showOPCServer() {
            const response = await OPCServerService.showOPCServer(this.id)
            this.data = response.data
        },
        async showStation() {
            const response = await StationService.showStation(this.id)
            this.data = response.data
        },
        async showCustomer() {
            const response = await CustomerService.showCustomer(this.id)
            this.data = response.data
        },
        async showUser() {
            const response = await AuthService.showUser(this.id)
            this.data = response.data
        },
        async editData() {
            Object.keys(this.data).forEach(key => {
                let column = this.$store.state.ibn.columns[this.tab].find(
                    item => item.field === key
                )
                if (column && column.allowNull && this.data[key] === "") {
                    this.data[key] = null
                } else if (this.data[key] === null) {
                    delete this.data[key]
                }
            })

            try {
                switch (this.tab) {
                case "station":
                    await StationService.editStation(this.id, this.data)
                    break
                case "opcserver":
                    await OPCServerService.editOPCServer(this.id, this.data)
                    break
                case "customer":
                    await CustomerService.editCustomer(this.id, this.data)
                    break
                case "user":
                    await AuthService.editUser(this.id, this.data)
                    break
                }
                this.$router.push({ name: "startup", params: { tab: this.tab } })
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            }
        }
    }
}
</script>

<style scoped></style>
