<template>
    <input
        ref="input"
        :class="[
            fullwidth ? 'is-full-width' : '',
            bold ? 'headline-section ' : '',
            fitContentLength ? 'adjust-input-width' : '',
            'borderless-input is-white is-paddingless'
        ]"
        :placeholder="placeholder"
        :style="{ 'text-transform': 'none !important', 'color': '#FFFFFF', 'font-size': fontSize }"
        :value="value"
        type="text"
        @input="adjustInputWidth(true)"
        @keyup.enter="$emit('enter', $event)"
        @keyup.esc="$emit('escape', $event)"
    >
</template>
<script>
export default {
    name: "BorderlessInput",
    props: {
        placeholder: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        fullwidth: {
            type: Boolean,
            default: true
        },
        bold: {
            type: Boolean,
            default: true
        },
        fontSize: {
            type: String,
            default: "1rem"
        },
        fitContentLength: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        let vm = this

        const resizeObserver = new ResizeObserver(function() {
            vm.adjustInputWidth()
        })

        resizeObserver.observe(this.$refs.input)

        setTimeout(() => {
            vm.adjustInputWidth()
        }, 500)
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        blur() {
            this.$refs.input.blur()
            this.adjustInputWidth()
        },
        adjustInputWidth() {
            if(this.$refs.input) {
                this.$refs.input.style.width = 0
                this.$refs.input.style.width = this.$refs.input.scrollWidth + 'px'
            }
        }
    }
}
</script>
