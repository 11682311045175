<template>
    <ibnSidebar>
        <section>
            <form @submit.prevent="addData()">
                <b-field
                    v-for="column in $store.state.ibn.columns[tab].filter(column => !isNaN(column.minRightsToModify) ? $store.state.user.Rights >= column.minRightsToModify : true)"
                    :key="column.field"
                    :label="column.label"
                >
                    <b-checkbox
                        v-if="column.type === 'checkbox'"
                        v-model="data[column.field]"
                        type="is-info"
                    >
                        {{ column.label }}
                    </b-checkbox>
                    <b-select
                        v-else-if="column.field === 'Customer.Name'"
                        v-model="data.CustomerID"
                        placeholder="Kunde auswählen"
                        required
                    >
                        <option
                            v-for="c in customer"
                            :key="c.ID"
                            :value="c.ID"
                        >
                            {{ c.Name }}
                        </option>
                    </b-select>
                    <b-input
                        v-else
                        :key="key"
                        v-model="data[column.field]"
                        :max="isNaN(column.max) ? null : column.max"
                        :min="isNaN(column.min) ? null : column.min"
                        :step="
                            isNaN(column.step) && column.step !== 'any' ? null : column.step
                        "
                        :type="column.type"
                    />
                </b-field>
                <b-field>
                    <button class="button is-info" type="submit">
                        <save-icon class="has-margin-right-5" />Speichern
                    </button>
                    <button
                        class="button is-danger is-outlined has-margin-left-10"
                        @click.prevent="$router.push({ name: 'startup', params: { tab } })"
                    >
                        Abbrechen
                    </button>
                </b-field>
            </form>
        </section>
    </ibnSidebar>
</template>

<script>
import OPCServerService from "../services/OPCServerService"
import StationService from "../services/StationService"
import ibnSidebar from "../components/ibnSidebar"
import AuthService from "../services/AuthService"
import CustomerService from "../services/CustomerService"
import { SaveIcon } from "vue-feather-icons"

export default {
    name: "StartupCreate",
    components: {
        ibnSidebar,
        SaveIcon
    },
    props: {
        tab: String
    },
    data() {
        return {
            data: {},
            customer: [],
            key: 0
        }
    },
    async mounted() {
        this.$store.state.ibn.columns[this.tab].map(item => {
            switch (item.field) {
            case "Port":
                this.data[item.field] = "4840"
                break
            case "DataBlock":
                this.data[item.field] = "DB ALFlex Trend"
                break
            case "MinDelta":
                this.data[item.field] = 0.0
                break
            default:
                this.data[item.field] = null
            }
        })

        if (["opcserver", "user"].includes(this.tab)) {
            this.customer = (await CustomerService.getCustomer()).data
        }

        this.key++

        console.log(this.data)
        this.hideLoadingScreen()
    },
    methods: {
        async addData() {
            try {
                Object.keys(this.data).forEach(key => {
                    let column = this.$store.state.ibn.columns[this.tab].find(
                        item => item.field === key
                    )
                    if (column && column.allowNull && this.data[key] === "") {
                        this.data[key] = null
                    } else if (this.data[key] === null) {
                        delete this.data[key]
                    }
                })

                switch (this.tab) {
                case "station":
                    await StationService.addStation(this.data)
                    break
                case "opcserver":
                    await OPCServerService.addOPCServer(this.data)
                    break
                case "customer":
                    await CustomerService.addCustomer(this.data)
                    break
                case "user":
                    await AuthService.register(this.data)
                    break
                }
                this.$router.push({ name: "startup", params: { tab: this.tab } })
            } catch (err) {
                console.error(
                    "Es ist ein Fehler beim Laden der Seite aufgetreten.",
                    err
                )
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Serververbindung fehlgeschlagen."
                )
            }
        }
    }
}
</script>

<style scoped></style>
