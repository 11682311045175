<template>
    <div class="container justify-center align-items-center has-margin-top-auto has-margin-bottom-auto">
        <h1
            class="title has-text-white is-bottom-marginless is-flex justify-center"
            style="font-size: 3rem; font-family: Inter; font-weight: 900"
        >
            ALFlex Trend
        </h1>
        <div class="container section has-padding-top-20 has-padding-bottom-20" style="max-width: 45rem;">
            <form @submit.prevent="doLogin">
                <b-notification
                    :closable="false"
                    :type="error ? 'is-danger' : 'is-info'"
                    aria-close-label="close notification"
                    role="alert"
                >
                    <div v-if="error" style="white-space: pre">
                        <x-circle-icon class="has-margin-right-10" />{{ error }}
                        {{
                            isNaN(parseInt(next))
                                ? ""
                                : `\nBitte versuchen Sie es in ${next} Sekunde${
                                    next !== 1 ? "n" : ""
                                } erneut.`
                        }}
                    </div>
                    <div v-else>
                        <info-icon class="has-margin-right-10" />Bitte loggen Sie
                        sich ein, um auf ALFlex Trend zugreifen zu können.
                    </div>
                </b-notification>

                <b-field label="E-Mail-Adresse">
                    <b-input
                        ref="email"
                        v-model="login.email"
                        placeholder="E-Mail Adresse"
                        required
                        type="email"
                    />
                </b-field>

                <b-field label="Passwort">
                    <b-input
                        v-model="login.password"
                        password-reveal
                        placeholder="Passwort"
                        required
                        type="password"
                    />
                </b-field>

                <button
                    class="button is-primary is-light"
                    style="font-weight: 700"
                    type="submit"
                >
                    <log-in-icon class="has-margin-right-5" />Anmelden
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import AuthService from "@/services/AuthService"
import { InfoIcon, LogInIcon, XCircleIcon } from "vue-feather-icons"
import Vue from 'vue'

export default {
    name: "LoginIndex",
    components: {
        InfoIcon,
        LogInIcon,
        XCircleIcon
    },
    props: {
        redirect: Object
    },
    data() {
        return {
            login: {
                email: null,
                password: null,
                csrf: null
            },
            error: null,
            next: null
        }
    },
    async mounted() {
        if (this.$store.state.token) {
            this.$router.push({ name: "dashboard" })
        } else {
            // check if user auth is enabled
            try {
                const response = await AuthService.getAuthStatus()
                if (response.data) {
                    this.checkAuthStatus(response.data.authEnabled)
                }
                // no login required
                this.$router.push({ name: "dashboard" })
            } catch (e) {
                if (e.response.data) {
                    this.checkAuthStatus(e.response.data.authEnabled)
                }

                const csrfResponse = await AuthService.getCSRFToken()
                this.login.csrf = csrfResponse.data.token

                Vue.nextTick(() => {
                    this.$refs.email.focus()
                })
            }
        }
        this.hideLoadingScreen()
    },
    methods: {
        async doLogin() {
            if (isNaN(parseInt(this.next))) {
                try {
                    const response = await AuthService.login(this.login)
                    this.error = null
                    this.$store.commit("setToken", response.data.token)
                    this.$store.commit("setUser", response.data.user)
                    this.$store.commit("setSession", response.data.session)

                    if (this.redirect) {
                        console.log("redirecting to", this.redirect)
                        const { name, params } = this.redirect
                        this.$router.push({ name, params })
                    } else {
                        this.$router.push({ name: "dashboard" })
                    }
                } catch (error) {
                    let responseError = error.response.data
                    this.login.password = null
                    this.error = responseError.error
                    if (!isNaN(responseError.next)) {
                        this.next = responseError.next
                        let interval = setInterval(() => {
                            this.next--
                            if (this.next <= 0) {
                                clearInterval(interval)
                                this.next = null
                                this.error = null
                            }
                        }, 1000)
                    }
                }
            }
        }
    }
}
</script>

<style>
label.label {
  font-family: Inter !important;
}
</style>
