<template>
    <div class="is-full-width">
        <Navbar>
            <template slot="icon">
                <settings-icon class="has-margin-right-10" />
            </template>
            <template slot="title">
                ALFlex Trend
            </template>
            <b-navbar-item v-if="$store.state.token" tag="div">
                <b-dropdown aria-role="list" hoverable position="is-bottom-left">
                    <button slot="trigger" class="button is-dark">
                        <user-icon class="has-margin-right-5" />{{ $store.state.user.Email }}
                    </button>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="$router.push({ name: 'userSettings' })"
                    >
                        <tool-icon class="has-margin-right-5" />Benutzereinstellungen
                    </b-dropdown-item>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="logout"
                    >
                        <log-out-icon class="has-margin-right-5" />Abmelden
                    </b-dropdown-item>
                </b-dropdown>
            </b-navbar-item>
            <b-navbar-item class="has-margin-right-10" tag="div">
                <BackButton />
            </b-navbar-item>
        </Navbar>
        <div class="container">
            <div class="has-margin-top-15 has-margin-left-15 has-margin-right-15">
                <Header>
                    <user-icon slot="icon" />
                    Benutzer
                </Header>

                <!-- Benutzerinformationen -->
                <article class="panel dark-panel no-border">
                    <p class="panel-heading">
                        <user-check-icon class="has-margin-right-5" />Informationen
                    </p>
                    <transition-group name="fade">
                        <div key="0" class="panel-block">
                            <span class="text-white" style="font-weight: 400">
                                <b>E-Mail-Adresse</b>: {{ $store.state.user.Email }}
                            </span>
                        </div>
                        <div key="1" class="panel-block">
                            <span class="text-white" style="font-weight: 400">
                                <b>Kunde</b>: {{ $store.state.user.Customer.Name }}
                            </span>
                        </div>
                        <div key="2" class="panel-block">
                            <span class="text-white" style="font-weight: 400">
                                <b>Berechtigungsstufe</b>:
                                {{
                                    $store.state.rightDescriptions[$store.state.user.Rights] ||
                                        $store.state.user.Rights
                                }}
                            </span>
                        </div>
                        <div
                            v-if="!!$store.state.user.Customer.AlertMailActive"
                            key="3"
                            class="panel-block"
                        >
                            <span class="text-white" style="font-weight: 400">
                                <b-checkbox v-model="alert" type="is-info">
                                    E-Mail-Benachrichtigungen bei Über- bzw. Unterschreitung der
                                    Grenzwerte
                                </b-checkbox>
                            </span>
                        </div>
                    </transition-group>
                </article>

                <!-- Sicherheitseinstellungen -->
                <article class="panel dark-panel no-border">
                    <p class="panel-heading">
                        <shield-icon class="has-margin-right-5" />Sicherheit
                    </p>
                    <transition-group name="fade">
                        <div key="0" class="panel-block">
                            <span class="text-white is-full-width" style="font-weight: 400">
                                <form class="is-quarter-width-desktop" @submit.prevent="changePassword">
                                    <b-input
                                        v-model="password"
                                        password-reveal
                                        placeholder="Neues Passwort festlegen"
                                        required
                                        type="password"
                                        class="has-margin-bottom-10"
                                    />
                                    <b-input
                                        v-model="passwordConfirm"
                                        password-reveal
                                        placeholder="Neues Passwort wiederholen"
                                        required
                                        type="password"
                                        class="has-margin-bottom-10"
                                    />
                                    <button type="submit" class="button dark-button is-flex flex-row align-items-center">
                                        <lock-icon />
                                        <span class="has-margin-left-5">Passwort ändern</span>
                                    </button>
                                </form>
                            </span>
                        </div>
                    </transition-group>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/Navbar"
import BackButton from "../components/BackButton"
import Header from "../components/Header"
import AuthService from "../services/AuthService"
import {
    ToolIcon,
    LogOutIcon,
    UserCheckIcon,
    UserIcon,
    SettingsIcon,
    ShieldIcon,
    LockIcon
} from "vue-feather-icons"

export default {
    name: "UserSettings",
    components: {
        Navbar,
        BackButton,
        Header,
        UserIcon,
        UserCheckIcon,
        ToolIcon,
        LogOutIcon,
        SettingsIcon,
        ShieldIcon,
        LockIcon
    },
    data() {
        return {
            alert: !!this.$store.state.user.AlertMail,
            password: null,
            passwordConfirm: null,
            gotCSRFToken: false
        }
    },
    watch: {
        async alert(newVal) {
            // api endpoint -> change AlertMail property
            await AuthService.editLoggedInUser({
                AlertMail: newVal ? 1 : 0
            })
            await this.reload()
        },
        async password() {
            if(!this.gotCSRFToken) {
                await AuthService.getCSRFToken()
                this.gotCSRFToken = true
            }
        }
    },
    async mounted() {
        await this.reload()
        this.hideLoadingScreen()
    },
    methods: {
        async reload() {
            const response = await AuthService.getAuthStatus()
            if (response.data) {
                if (!response.data.authEnabled) {
                    this.$router.push({ name: "dashboard" })
                }
                this.$store.commit("setUser", response.data.user)
                this.checkAuthStatus(response.data.authEnabled)
            }
        },
        async changePassword() {
            if(this.password !== this.passwordConfirm) {
                this.errorAlert("Die eingegebenen Passwörter stimmen nicht überein.")
                return
            }

            try {
                await AuthService.changePassword({
                    Password: this.password,
                    PasswordConfirm: this.passwordConfirm,
                    CSRF: this.$cookies.get("CSRF-TOKEN")
                })

                this.password = null
                this.passwordConfirm = null

                this.successAlert("Ihr Passwort wurde erfolgreich geändert.", false)
            } catch (err) {
                console.error(err)
                this.errorAlert(
                    err.response.data.error
                        ? err.response.data.error
                        : "Ihr Passwort konnte nicht geändert werden."
                )
            }

            // auch im Fehlerfall soll neuer CSRF Token generiert werden
            await AuthService.getCSRFToken()
        }
    }
}
</script>

<style scoped></style>
