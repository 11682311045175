var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"is-full-width"},[_c('Navbar',[_c('template',{slot:"icon"},[_c('settings-icon',{staticClass:"has-margin-right-10"})],1),_c('template',{slot:"title"},[_vm._v(" ALFlex Trend ")]),(_vm.$store.state.token)?_c('b-navbar-item',{attrs:{"tag":"div"}},[_c('b-dropdown',{attrs:{"aria-role":"list","hoverable":"","position":"is-bottom-left"}},[_c('button',{staticClass:"button is-dark",attrs:{"slot":"trigger"},slot:"trigger"},[_c('user-icon',{staticClass:"has-margin-right-5"}),_vm._v(_vm._s(_vm.$store.state.user.Email)+" ")],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$router.push({ name: 'userSettings' })}}},[_c('tool-icon',{staticClass:"has-margin-right-5"}),_vm._v("Benutzereinstellungen ")],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.logout}},[_c('log-out-icon',{staticClass:"has-margin-right-5"}),_vm._v("Abmelden ")],1)],1)],1):_vm._e(),_c('b-navbar-item',{staticClass:"has-margin-right-10",attrs:{"tag":"div"}},[_c('BackButton')],1)],2),_c('div',{staticClass:"container"},[_c('div',{staticClass:"has-margin-top-15 has-margin-left-15 has-margin-right-15"},[_c('Header',[_c('help-circle-icon',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(" Hilfe ")],1),_c('article',{staticClass:"panel dark-panel no-border"},[_c('p',{staticClass:"panel-heading"},[_c('mail-icon',{staticClass:"has-margin-right-5"}),_vm._v("Kontakt ")],1),_vm._m(0),_vm._m(1),_vm._m(2)]),_c('article',{staticClass:"panel dark-panel no-border"},[_c('p',{staticClass:"panel-heading"},[_c('info-icon',{staticClass:"has-margin-right-5"}),_vm._v("Lizenzen ")],1),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('b',[_vm._v("E-Mail")]),_vm._v(": "),_c('a',{staticClass:"nav-link action",attrs:{"href":"mailto:info@albertfrey.de?subject=ALFlex%20Trend"}},[_vm._v("info@albertfrey.de")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('b',[_vm._v("Telefon")]),_vm._v(": "),_c('a',{staticClass:"nav-link action",attrs:{"href":"tel:+49830292020"}},[_vm._v("+49 (0) 8302 9202-0")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('b',[_vm._v("Anschrift")]),_vm._v(": Marktoberdorfer Straße 24, 87616 Wald ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/apexcharts/apexcharts.js","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("ApexCharts")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/axios/axios","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("Axios")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/buefy/buefy","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("Buefy")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/jgthms/bulma","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("Bulma")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/moment/moment","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("Moment.js")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/vuejs/vue","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("Vue.js")]),_vm._v(" (MIT Lizenz) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-block"},[_c('span',{staticClass:"text-white",staticStyle:{"font-weight":"400"}},[_c('a',{staticClass:"nav-link action",attrs:{"href":"https://github.com/egoist/vue-feather-icons","rel":"noopener noreferrer","target":"_blank"}},[_vm._v("vue-feather-icons")]),_vm._v(" (MIT Lizenz) ")])])
}]

export { render, staticRenderFns }