<template>
    <a
        class="button is-dark"
        :title="backToHome ? 'Zurück zur Home-Seite' : 'Zurück'"
        @click="navigate"
    >
        <home-icon v-if="backToHome" />
        <arrow-left-icon v-else />
        <span class="has-margin-left-5">zurück</span>
    </a>
</template>

<script>
import {
    HomeIcon,
    ArrowLeftIcon
} from "vue-feather-icons"

export default {
    name: "BackButton",
    components: {
        HomeIcon,
        ArrowLeftIcon
    },
    props: {
        backToHome: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        navigate() {
            if(this.backToHome)
                this.$router.push({
                    name: 'dashboard',
                    params: { permalink: this.$store.state.profile.Permalink }
                })
            else
                this.$router.go(-1)
        }
    }
}
</script>

<style scoped>

</style>
