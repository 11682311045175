<template>
    <div class="is-full-width">
        <Navbar>
            <template slot="icon">
                <settings-icon class="has-margin-right-10" />
            </template>
            <template slot="title">
                ALFlex Trend
            </template>
            <b-navbar-item
                v-if="$store.state.token"
                tag="div"
            >
                <b-dropdown
                    aria-role="list"
                    hoverable
                    position="is-bottom-left"
                >
                    <button
                        slot="trigger"
                        class="button is-dark"
                    >
                        <user-icon class="has-margin-right-5" />{{ $store.state.user.Email }}
                    </button>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="$router.push({ name: 'userSettings' })"
                    >
                        <tool-icon class="has-margin-right-5" />Benutzereinstellungen
                    </b-dropdown-item>
                    <b-dropdown-item
                        aria-role="listitem"
                        @click="logout"
                    >
                        <log-out-icon class="has-margin-right-5" />Abmelden
                    </b-dropdown-item>
                </b-dropdown>
            </b-navbar-item>
            <b-navbar-item
                class="has-margin-right-10"
                tag="div"
            >
                <BackButton />
            </b-navbar-item>
        </Navbar>
        <div class="container">
            <div class="has-margin-top-15 has-margin-left-15 has-margin-right-15">
                <Header>
                    <help-circle-icon slot="icon" />
                    Hilfe
                </Header>

                <!-- Kontaktinfos -->
                <article class="panel dark-panel no-border">
                    <p class="panel-heading">
                        <mail-icon class="has-margin-right-5" />Kontakt
                    </p>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <b>E-Mail</b>:
                            <a
                                class="nav-link action"
                                href="mailto:info@albertfrey.de?subject=ALFlex%20Trend"
                            >info@albertfrey.de</a>
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <b>Telefon</b>:
                            <a
                                class="nav-link action"
                                href="tel:+49830292020"
                            >+49 (0) 8302 9202-0</a>
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <b>Anschrift</b>: Marktoberdorfer Straße 24, 87616 Wald
                        </span>
                    </div>
                </article>

                <!-- Lizenzen -->
                <article class="panel dark-panel no-border">
                    <p class="panel-heading">
                        <info-icon class="has-margin-right-5" />Lizenzen
                    </p>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/apexcharts/apexcharts.js"
                                rel="noopener noreferrer"
                                target="_blank"
                            >ApexCharts</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/axios/axios"
                                rel="noopener noreferrer"
                                target="_blank"
                            >Axios</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/buefy/buefy"
                                rel="noopener noreferrer"
                                target="_blank"
                            >Buefy</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/jgthms/bulma"
                                rel="noopener noreferrer"
                                target="_blank"
                            >Bulma</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/moment/moment"
                                rel="noopener noreferrer"
                                target="_blank"
                            >Moment.js</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/vuejs/vue"
                                rel="noopener noreferrer"
                                target="_blank"
                            >Vue.js</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                    <div class="panel-block">
                        <span
                            class="text-white"
                            style="font-weight: 400"
                        >
                            <a
                                class="nav-link action"
                                href="https://github.com/egoist/vue-feather-icons"
                                rel="noopener noreferrer"
                                target="_blank"
                            >vue-feather-icons</a>
                            (MIT Lizenz)
                        </span>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar"
import BackButton from "../components/BackButton"
import Header from "@/components/Header"
import {
    HelpCircleIcon,
    ToolIcon,
    LogOutIcon,
    MailIcon,
    InfoIcon,
    UserIcon,
    SettingsIcon
} from "vue-feather-icons"

export default {
    name: "HelpIndex",
    components: {
        Navbar,
        BackButton,
        Header,
        HelpCircleIcon,
        LogOutIcon,
        UserIcon,
        ToolIcon,
        MailIcon,
        InfoIcon,
        SettingsIcon
    },
    mounted() {
        this.hideLoadingScreen()
    }
}
</script>

<style scoped>
a.action {
  color: #00d1b2;
}
a.action:hover {
  color: #1abc9c;
}
</style>
