<template>
    <button
        :class="[
            'button is-small is-flex flex-column',
            type,
            marginLeft ? 'has-margin-left-5' : ''
        ]"
        :style="{
            width: size,
            height: size
        }"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: "RoundedButton",
    props: {
        type: {
            type: String,
            default: 'is-primary'
        },
        marginLeft: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: '24px'
        }
    }
}
</script>

<style scoped>
button {
  border-radius: 999px !important;
}

svg {
  min-width: 14px;
  min-height: 14px;
}
</style>
