import axios from "axios"
import router from "../router"
import store from "../store.js"
import { methods } from "../methods.js"

export default () => {
    const api = axios.create({
        baseURL: process.env.VUE_APP_API_BASEURL || "/api/v1",
        ...(!!store.state.token && {
            headers: {
                ...(!!store.state.token && {
                    Authorization: `Bearer ${store.state.token}`
                }),
                ...(!store.state.token &&
          store.state.ibn.password && {
                    Authorization: `Basic ${store.state.ibn.password}`
                })
            }
        })
    })

    api.interceptors.response.use(undefined, error => {
        if (error.response?.status === 401 &&
            store.state.route.name !== "login" &&
            error.response.data !== "basicAuthFailed"
        ) {
            const { name, params } = router.currentRoute

            methods.logout(false, {
                ibn: error.response?.data?.auth === "basic",
                redirect: {
                    name,
                    params
                }
            })
        }
        throw error
    })

    return api
}
